import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatButtonModule, MatCardModule, MatDialogModule, MatInputModule, MatTableModule, 
  MatToolbarModule, MatMenuModule,MatIconModule, MatProgressSpinnerModule, MatGridListModule, MatSidenavModule,
  MatListModule, MatPaginatorModule, MatAutocompleteModule, MatButtonToggleModule, MatSelectModule, MatSnackBarModule, 
  MatRadioModule, MatRippleModule, MatSliderModule, MatTooltipModule, MatSlideToggleModule, MatExpansionModule, MatCheckboxModule, 
  MatTabsModule, MatStepperModule, MatDatepickerModule, MatNativeDateModule, MatDividerModule, 
} from '@angular/material';
 
@NgModule({
  declarations: [],
  imports: [
    CommonModule, 
    MatToolbarModule,
    MatButtonModule, 
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatSidenavModule,
    MatListModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSliderModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatTabsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDividerModule
  ],
  exports: [
   CommonModule,
   MatToolbarModule, 
   MatButtonModule, 
   MatCardModule, 
   MatInputModule, 
   MatDialogModule, 
   MatTableModule, 
   MatMenuModule,
   MatIconModule,
   MatProgressSpinnerModule,
   MatGridListModule,
   MatSidenavModule,
   MatListModule,
   MatPaginatorModule,
   MatAutocompleteModule,
   MatButtonToggleModule,
   MatSelectModule,
   MatSnackBarModule,
   MatRadioModule,
   MatRippleModule,
   MatSliderModule,
   MatTooltipModule,
   MatSlideToggleModule,
   MatExpansionModule,
   MatCheckboxModule,
   MatTabsModule, 
   MatStepperModule,
   MatDatepickerModule,
   MatNativeDateModule
  ]
})
export class MaterialModule { }
