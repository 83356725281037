import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioService } from '../../../services/usuario.service';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DialogUsuarioComponent } from './dialog-usuario/dialog-usuario.component';

export interface UserData {
  idusuario: string,
  idgrupo: string, 
  login: string,
  password: string,
  nombre: string,
  apellidopaterno: string,
  apellidomaterno: string, 
  telefono: string, 
  email: string,
  nombregrupo: string,
  activo: boolean
}

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})

export class UsuarioComponent implements OnInit {
  lstUsuarios: any;
  displayedColumns: string[] = ['nombregrupo', 'login', 'nombrecompleto', 'email', 'activo', 'acciones'];
  dataSource: MatTableDataSource<UserData>;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 
  
  constructor(private usuarioService: UsuarioService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }
 

  async ngOnInit() 
  {
    this.configureUsuarios();
  }
  async configureUsuarios() {
    this.lstUsuarios = await this.usuarioService.getUsuarios();
    this.dataSource = new MatTableDataSource(this.lstUsuarios);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  async agregarUsuarioDialog() {
    
    const dialogo = this.dialog.open(DialogUsuarioComponent, {
      height: '720px',
      width: '480px',
      disableClose: true,
      data: { editar: false }
    });

    dialogo.afterClosed().subscribe(async usuario => {
     
      if (usuario) {
        try {
          const response = await this.usuarioService.postUsuario(usuario).toPromise();
          this.configureUsuarios();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
    
  }

  editarUsuarioDialog(usuario: any) { 
    const dialogo = this.dialog.open(DialogUsuarioComponent, {
      height: '720px',
      width: '480px',
      disableClose: true,
      data: { usuario: usuario, editar: true }
    });

    dialogo.afterClosed().subscribe(async usuario => {
     
      if (usuario) {
        try {
          const response = await this.usuarioService.editUsuario(usuario).toPromise();
          this.configureUsuarios();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async disableUsuario(usuario: any) {
    if (usuario) {
      try {
        await this.usuarioService.disableUsuario(usuario).toPromise();
        this.configureUsuarios();

        this.snackBar.open('Los datos se guardaron exitosamente.', '', {
          duration: 5000,
        });
      } catch (error) {
        console.log(error);
        this.snackBar.open('Ocurrió un error al guardar.', '', {
          duration: 5000,
        });
      }
    }
  }

}
