import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { VisorData } from 'src/app/components/visor/visor.component';
import { GrupoData } from 'src/app/components/grupo/grupo.component';

@Injectable({
  providedIn: 'root'
})
export class ServicioMapaService {

  constructor(private http: HttpClient) { }

  getServicioMapas() {
    return this.http.get<any>(Constants.API_URL + '/seguridad/servicioMapa/getServicioMapas').toPromise();
  }

  postServicioMapa(servicioMapa: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/servicioMapa/postServicioMapa`, servicioMapa);
  }

  editServicioMapa(servicioMapa: any) {
    return this.http.put(`${Constants.API_URL}/seguridad/servicioMapa/editServicioMapa`, servicioMapa);
  }

  deleteServicioMapa(servicioMapa: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/servicioMapa/deleteServicioMapa`, servicioMapa);
  }

  getCapasGeobase() {
    return this.http.get<any>(Constants.API_URL + '/seguridad/servicioMapa/getCapasGeobase').toPromise();
  }

  getCapasPorServicio(servicioMapa: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/servicioMapa/getCapasPorServicio`, servicioMapa);
  }

  getServiciosPermisoByVisor(visor: VisorData) {
    return this.http.get<any>(Constants.API_URL + '/seguridad/servicioMapa/getServiciosPermisoByVisor?idvisor=' + visor.idvisor).toPromise();
  }

  getServiciosPermisoByVisorAndGrupo(visor: VisorData, grupo: GrupoData) {
    return this.http.get<any>(Constants.API_URL + '/seguridad/servicioMapa/getServiciosPermisoByVisorAndGrupo?idvisor=' + visor.idvisor + '&idgrupo=' + grupo.idgrupo).toPromise();
  }

  asignaCapasServicio(servicioMapa: any, capas: any) {
    let body = {
      servicio: servicioMapa,
      capas: capas,
    };
    return this.http.post(`${Constants.API_URL}/seguridad/servicioMapa/asignaCapasServicio`, body);
  }
  asignaServiciosVisor(visor: any, servicios: any) {
    let body = {
      visor: visor,
      servicios: servicios,
    };
    return this.http.post(`${Constants.API_URL}/seguridad/servicioMapa/asignaServiciosVisor`, body);
  }
  asignaServiciosVisorAndGrupo(grupo: any, visor: any, servicios: any) {
    let body = {
      visor: visor,
      grupo: grupo,
      servicios: servicios,
    };
    return this.http.post(`${Constants.API_URL}/seguridad/servicioMapa/asignaServiciosVisorAndGrupo`, body);
  }
}
