import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HerramientaData } from '../herramienta.component';

@Component({
  selector: 'app-dialog-herramienta',
  templateUrl: './dialog-herramienta.component.html',
  styleUrls: ['./dialog-herramienta.component.css']
})
export class DialogHerramientaComponent implements OnInit {
  herramienta: HerramientaData;
  titulo = 'Nueva Herramienta';
  frmCampos: FormGroup;
  
  constructor(private dialogRef: MatDialogRef<DialogHerramientaComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              ) { }

  ngOnInit() {
    this.frmCampos = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(2)]),
      descripcion: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });

    if (this.data.editar) {
      this.titulo = 'Editar Herramienta';
      this.herramienta = this.data.herramienta;
    } else {
      this.herramienta = { idherramienta: '', nombre: '', descripcion: ''};
    }
  }
  
  cancelar() {
    this.dialogRef.close(undefined);
  }
  guardar() {
    this.dialogRef.close(this.herramienta);
  }


}