import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SolicitudService } from '../../../../services/solicitud.service';
import { LoginService } from '../../../../services/login.service';

import { ISolicitud, IHttpResponse } from '../../../../classes/interfaces';
import { MatSnackBar } from '@angular/material';
import { Constants } from 'src/commons/constants';

@Component({
  selector: 'app-mis-tramites',
  templateUrl: './mis-tramites.component.html',
  styleUrls: ['./mis-tramites.component.css']
})
export class MisTramitesComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  filesToUpload: Array<File>;

  constructor(private ss: SolicitudService,
              private loginService: LoginService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.configuraTramites();
  }
  async configuraTramites() {
   const respSolicitudes: any = await this.ss.getSolicitudes(this.loginService.user.idusuario);
    if (respSolicitudes.status === 200 && respSolicitudes.data) {
      this.ss.lstSolicitudes = respSolicitudes.data;
    } else {
      this.snackBar.open('No hay solicitudes. Favor de hacer una nueva solicitud', 'X', {duration: 3000});
    }
  }
  async descargarCartaComprmiso(solicitud: ISolicitud) {
    if (solicitud.carta_sinfirmar) {
      window.open(Constants.FILES_URL+solicitud.carta_sinfirmar, '_blank');
    } else {
      this.snackBar.open('No se puede descargar la carta compromiso. Favor de volver a generar otro folio', 'X', {duration: 3000});
    }
  }
  async fileDocumentChangeEvent(fileInput: any, i: number, solicitud: ISolicitud){
   try {
    this.filesToUpload = <Array<File>> fileInput.target.files;
    if (this.filesToUpload.length === 1) {
      var formData: FormData = new FormData();
      formData.append("upload", this.filesToUpload[0], this.filesToUpload[0].name);
      const resp: any = await this.ss.postUpload(solicitud, formData);
      if(resp.status === 200) {
        this.snackBar.open('Carta compromiso firmada subida con éxito. A la brevedad se comunicarán con usted para dar seguimiento', 'X', {duration: 20000});
        this.configuraTramites();
      }
    } else {
      this.snackBar.open('Solamente debe seleccionar un archivo', 'X', {duration: 3000});
    }
   } catch(error) {     
      this.snackBar.open('No se pudo subir la carta compromiso firmada. Favor de volverlo a intentar', 'X', {duration: 3000});
   }
  }
}
