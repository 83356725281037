import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../commons/constants';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private http: HttpClient) { }

  getUsuarios() {
    return this.http.get<any[]>(Constants.API_URL + '/seguridad/usuario/getUsuarios').toPromise();
  }
  postUsuario(usuario: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/usuario/postUsuario`, usuario);
  }
  editUsuario(usuario: any) {
    return this.http.put(`${Constants.API_URL}/seguridad/usuario/editUsuario`, usuario);
  }
  disableUsuario(usuario: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/usuario/disableUsuario`, usuario);
  }
}
