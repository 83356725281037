import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';

@Injectable({
  providedIn: 'root'
})
export class GrupoService {

  constructor(private http: HttpClient) { }

  getGrupos() {
    return this.http.get<any>(Constants.API_URL + '/seguridad/grupo/getGrupos').toPromise();
  }
  postGrupo(grupo: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/grupo/postGrupo`, grupo);
  }
  editGrupo(grupo: any) {
    return this.http.put(`${Constants.API_URL}/seguridad/grupo/editGrupo`, grupo);
  }
  getUsuariosInGrupo(idgrupo: string) {
    return this.http.get<any>(Constants.API_URL + '/seguridad/grupo/getUsuariosInGrupo?idgrupo=' + idgrupo).toPromise();
  }
  getUsuariosNotInGrupo(idgrupo: string) {
    return this.http.get<any>(Constants.API_URL + '/seguridad/grupo/getUsuariosNotInGrupo?idgrupo=' + idgrupo).toPromise();
  }
  asignaUsuariosGrupo(grupo: any, lstUsuarios: any) {
    return this.http.put(`${Constants.API_URL}/seguridad/grupo/asignaUsuariosGrupo`, {grupo: grupo, usuarios: lstUsuarios}).toPromise();
  }
}
