import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VisorService } from '../../../../services/visor.service';
import { VisorData } from '../visor.component';

@Component({
  selector: 'app-dialog-visor',
  templateUrl: './dialog-visor.component.html',
  styleUrls: ['./dialog-visor.component.css']
})
export class DialogVisorComponent implements OnInit {
  visor: VisorData;
  titulo = 'Nuevo Visor';
  frmCampos: FormGroup;
  
  constructor(private dialogRef: MatDialogRef<DialogVisorComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              ) { }

  ngOnInit() {
    this.frmCampos = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(4)]),
      descripcion: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });

    if (this.data.editar) {
      this.titulo = 'Editar Visor';
      this.visor = this.data.visor;
    } else {
      this.visor = { idvisor: '', nombre: '', descripcion: ''};
    }
  }
  
  cancelar() {
    this.dialogRef.close(undefined);
  }
  guardar() {
    this.dialogRef.close(this.visor);
  }


}