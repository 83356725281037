import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { ICirculoCiudadano, ITerritorio, IRedVial, IHttpResponse } from '../classes/interfaces';
@Injectable({
  providedIn: 'root'
})
export class CapturaCovidService {
  lstResponsable: Array<ICirculoCiudadano> = new Array<ICirculoCiudadano>(0);
  lstBeneficiario: Array<ICirculoCiudadano> = new Array<ICirculoCiudadano>(0);
  lstColonia: Array<ITerritorio> = new Array<ITerritorio>(0);
  lstRedVial: Array<IRedVial> = new Array<IRedVial>(0);
  lstRedVialCruce: Array<IRedVial> = new Array<IRedVial>(0);

  constructor(private http: HttpClient) { }
  GetResponsable() {
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/covid/GetResponsable`).toPromise();
  }
  
  GetColonia() {
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/catalogo/GetColonia`).toPromise();
  }

  GetRedVialByColonia(colonia: string){
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/catalogo/GetRedVialByColonia/${colonia}`).toPromise();
  }

  GetRedVialCruce(nombreRedVial: string, geom: string) {
    const body = {
      nombre: nombreRedVial,
      geometry: geom
    };
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/catalogo/GetRedVialCruce`, body).toPromise();
  }
  PostResponsable(circuloCiudadano: ICirculoCiudadano) {
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/covid/PostResponsable`, circuloCiudadano).toPromise();
  }

  PutResponsable(circuloCiudadano: ICirculoCiudadano) {
    return this.http.put<IHttpResponse>(`${Constants.API_URL}/covid/PutResponsable`, circuloCiudadano).toPromise();
  }

  DelResponsable(circuloCiudadano: ICirculoCiudadano) {
    return this.http.put<IHttpResponse>(`${Constants.API_URL}/covid/DelResponsable`, circuloCiudadano).toPromise();
  }
  // Vocales
  GetBeneficiario(idcirculociudadano: string) {
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/covid/GetBeneficiario/${idcirculociudadano}`).toPromise();
  }
  PostBeneficiario(vocal: ICirculoCiudadano) {
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/covid/PostBeneficiario`, vocal).toPromise();
  }

  PutBeneficiario(vocal: ICirculoCiudadano) {
    return this.http.put<IHttpResponse>(`${Constants.API_URL}/covid/PutBeneficiario`, vocal).toPromise();
  }

  DelBeneficiario(vocal: ICirculoCiudadano) {
    return this.http.put<IHttpResponse>(`${Constants.API_URL}/covid/DelBeneficiario`, vocal).toPromise();
  }

  
  postUpload(data: any) {
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/covid/uploadFotos`, data).toPromise();
  }

  GetCorteBeneficiariosCovid(fechainicio: string, fechafin: string, zona: string){
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/reporte/generaCorte?fechainicio=${fechainicio}&fechafin=${fechafin}&zona=${zona}`).toPromise();
  }
}
