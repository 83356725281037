import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ICirculoCiudadano, ITerritorio, IRedVial, IHttpResponse } from '../../../../classes/interfaces';
import { CirculoCiudadanoService } from '../../../../services/circulo-ciudadano.service';
import { LoginService } from '../../../../services/login.service';
import { MapaService } from '../../../../services/mapa.service';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators'

@Component({
  selector: 'app-dialog-captura',
  templateUrl: './dialog-captura.component.html',
  styleUrls: ['./dialog-captura.component.css']
})
export class DialogCapturaComponent implements OnInit {
  titulo = 'Agregar Circulo Ciudadano';
  ciudadano: ICirculoCiudadano = {
    id: '',
    idcirculociudadano: '',
		nombre: '',
		apellidopaterno: '',
    apellidomaterno: '',
    nombrecompleto: '',
    fechanacimiento: '',
		genero: 'Masculino',
		estadonacimiento: 'Jalisco',
    calle: '',
    cruce: '',
		noexterior: '',
		nointerior: '',
		codigopostal: '',
		colonia: '',
		municipio: '',
    estado: '',
    direccion: '',
		celular: '',
		tipocelular: '',
    telefonofijo: '',
		ocupacion: '',
		email: '',
		facebook: '',
		twitter: '',
		invitadopor: '',
		claveelector: '',
		clavemunicipioelector: '',
    seccionelector: '',
		curpelector: '',
		localidadelector: '',
		latitud: '',
    longitud: '',
    fechamodificacion: '',
    usuario: this.loginService.user.login,
    active: '',
    armzona: '',
    armsubzona: '',
  };
  frmCampos: FormGroup;

  // Colonia
  filteredOptions: Observable<ITerritorio[]>;
  myControl = new FormControl();

  // Redvial
  filteredOptionsCalle: Observable<IRedVial[]>;
  myControlCalle = new FormControl();
  calle: IRedVial;

  // RedVial Cruce
  filteredOptionsCruce: Observable<IRedVial[]>;
  myControlCruce = new FormControl();
  cruce: IRedVial;
  lstGenero: Array<String> = ['Masculino', 'Femenino'];
  lstTipoCelular: Array<String> = ['Android', 'IOS', 'Windows Phone', 'Otro'];
  constructor(private dialogRef: MatDialogRef<DialogCapturaComponent>,
              private loginService: LoginService,
              private circuloCiudadanoService: CirculoCiudadanoService,
              private mapaService: MapaService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.configurarValidadores();
    this.configurarAutocomplete();
    this.configurarAutocompleteCalle();
    
    if (!this.data.editar && this.data.isCiudadano) {
      this.titulo = "Agregar Ciudadano";
    } else if(this.data.editar && this.data.isCiudadano) {
      this.titulo = "Editar Ciudadano";
      this.ciudadano = this.data.ciudadano;
    } else if (!this.data.editar && !this.data.isCiudadano) {
      this.titulo = "Agregar Vocal";
      this.ciudadano.idcirculociudadano = this.data.ciudadano.id;
    } else if (this.data.editar && !this.data.isCiudadano) {
      this.titulo = "Editar Vocal";
      this.ciudadano = this.data.ciudadano;
    }
  }
  configurarValidadores() {
    this.frmCampos = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(1)]),
      apellidopaterno: new FormControl('', [Validators.required, Validators.minLength(1)]),
      apellidomaterno: new FormControl('', [Validators.required, Validators.minLength(1)]),
      // colonia: new FormControl('', [Validators.required, Validators.minLength(1)]),
      // calle: new FormControl('', [Validators.required, Validators.minLength(1)]),
      noexterior: new FormControl('', [Validators.required, Validators.minLength(1)]),
    });
  }
  // Autocomplete Colonia
  configurarAutocomplete() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filter(name) : this.circuloCiudadanoService.lstColonia.slice())
      );
  }
  
  displayFn(colonia?: ITerritorio): string | undefined {
    return colonia ? colonia.nombre : undefined;
  }
  
  private _filter(name: string): ITerritorio[] {
    const filterValue = name.toLowerCase();
    return this.circuloCiudadanoService.lstColonia.filter(option => option.nombre.toLowerCase().indexOf(filterValue) >= 0);
  }

  async autoCompleteColoniaChanged(colonia: ITerritorio) {
    try {
      this.ciudadano.armzona = colonia.armzona;
      this.ciudadano.armsubzona = colonia.armsubzona;
      this.ciudadano.colonia = colonia.nombre;
      const respRedVial: IHttpResponse = await this.circuloCiudadanoService.GetRedVialByColonia(colonia.nombre);  
      if (respRedVial.status === 200) {
        this.circuloCiudadanoService.lstRedVial = respRedVial.data;
        this.configurarAutocompleteCalle();
      }
      // Resaltamos la colonia seleccionada
      this.mapaService.clearVectorLayer(this.mapaService.vectorLayerColonia);
      this.mapaService.addFeatureAndZoom(this.mapaService.vectorLayerColonia, colonia.geom);
    } catch (error) {
      console.log(error);
    }
    
  }
  // Autocomplete en redvial
  configurarAutocompleteCalle() {
    this.filteredOptionsCalle = this.myControlCalle.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.redvial),
        map(name => name ? this._filterCalle(name) : this.circuloCiudadanoService.lstRedVial.slice())
      );
  }
  
  displayFnCalle(redvial?: IRedVial): string | undefined {
    return redvial ? redvial.redvial : undefined;
  }
  
  private _filterCalle(name: string): IRedVial[] {
    const filterValue = name.toLowerCase();
    return this.circuloCiudadanoService.lstRedVial.filter(option => option.redvial.toLowerCase().indexOf(filterValue) >= 0);
  }

  async autoCompleteCalleChanged(redvial: IRedVial) {
    try {
      this.ciudadano.calle = redvial.redvial
      this.calle = redvial;
      const respRedVialCruce: IHttpResponse = await this.circuloCiudadanoService.GetRedVialCruce(redvial.redvial, redvial.geom);  
      if (respRedVialCruce.status === 200) {
        this.circuloCiudadanoService.lstRedVialCruce = respRedVialCruce.data;
        this.configurarAutocompleteCruce();
      }
      // Resaltamos la redvial seleccionada
      this.mapaService.clearVectorLayer(this.mapaService.vectorLayerRedVial);
      this.mapaService.addFeatureLayer(this.mapaService.vectorLayerRedVial, redvial.geom);
      
    } catch (error) {
      console.log(error);
    }
  }
  // Autocomplete en Cruce de Calles
  configurarAutocompleteCruce() {
    this.filteredOptionsCruce = this.myControlCruce.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.redvial),
        map(name => name ? this._filterCruce(name) : this.circuloCiudadanoService.lstRedVialCruce.slice())
      );
  }
  
  displayFnCruce(redvial?: IRedVial): string | undefined {
    return redvial ? redvial.redvial : undefined;
  }
  
  private _filterCruce(name: string): IRedVial[] {
    const filterValue = name.toLowerCase();
    return this.circuloCiudadanoService.lstRedVialCruce.filter(option => option.redvial.toLowerCase().indexOf(filterValue) >= 0);
  }

  async autoCompleteCruceChanged(redvial: IRedVial) {
    try {
      this.ciudadano.cruce = redvial.redvial;
      this.cruce = redvial;
      // Resaltamos la redvial seleccionada
      this.mapaService.clearVectorLayer(this.mapaService.vectorLayerRedVialCruce);
      this.mapaService.addFeatureLayer(this.mapaService.vectorLayerRedVialCruce, redvial.geom);
      const respCruce: IHttpResponse = await this.mapaService.GetIntersectionPointWithBuffer(this.calle.geom, this.cruce.geom, 5);
      
      if (respCruce.status === 200 && respCruce.data.length > 0) {
        this.mapaService.clearVectorLayer(this.mapaService.vectorLayerUbicacion);
        const jsonCoordinates = JSON.parse(respCruce.data[0].geom);
        this.ciudadano.latitud = jsonCoordinates.coordinates[1];
        this.ciudadano.longitud = jsonCoordinates.coordinates[0];
        this.mapaService.addFeatureLayer(this.mapaService.vectorLayerUbicacion, respCruce.data[0].geom);
      }
    } catch (error) {
      console.log(error);
    }
  }
  async geoLocalizarCalleAndNExterior() {
    if (this.calle && this.ciudadano.noexterior) {
      const respGeo: IHttpResponse = await this.mapaService.GetGeoReferenciaByCalleAndNexterior(this.calle.geom, this.ciudadano.noexterior);
      
      if (respGeo.status === 200 && respGeo.data.length > 0) {
        this.mapaService.clearVectorLayer(this.mapaService.vectorLayerUbicacion);
        const jsonCoordinates = JSON.parse(respGeo.data[0].geom);
        this.ciudadano.latitud = jsonCoordinates.coordinates[1];
        this.ciudadano.longitud = jsonCoordinates.coordinates[0];
        this.mapaService.addFeatureLayer(this.mapaService.vectorLayerUbicacion, respGeo.data[0].geom);
      }
    }
  }
  cancelar(){
    this.dialogRef.close(null);
  }
  guardar() {
    this.ciudadano.active = 'true';
    this.dialogRef.close(this.ciudadano);
  }
}
