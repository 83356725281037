import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelComponent } from './panel.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { DialogConfirmacionComponent } from '../dialogs/dialog-confirmacion/dialog-confirmacion.component';
import { MapaComponent } from '../../components/mapa/mapa.component';

// Services 
import { MenuService } from '../../../services/menu.service';
import { LoginService } from '../../../services/login.service';
import { MenuPrincipalComponent } from './menu-principal/menu-principal.component';
import { NuevaSolicitudComponent } from './nueva-solicitud/nueva-solicitud.component';
import { MisTramitesComponent } from './mis-tramites/mis-tramites.component';
import { SolicitudService } from '../../../services/solicitud.service';
import { MapaService } from '../../../services/mapa.service';

@NgModule({
  declarations: [
    PanelComponent,
    MapaComponent,
    MenuPrincipalComponent,
    NuevaSolicitudComponent,
    MisTramitesComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
  ],
  entryComponents: [DialogConfirmacionComponent],
  providers: [LoginService, MenuService, SolicitudService, MapaService],
})

export class PanelModule { }
