import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LoginService } from '../../../../services/login.service';
import { SolicitudService } from '../../../../services/solicitud.service';
import { MapaService } from '../../../../services/mapa.service';

import { IHttpResponse, ITerritorio, IRedVial, IVerificacion, IGiro } from '../../../../classes/interfaces';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-nueva-solicitud',
  templateUrl: './nueva-solicitud.component.html',
  styleUrls: ['./nueva-solicitud.component.css']
})
export class NuevaSolicitudComponent implements OnInit {
  @Output() close = new EventEmitter<string>();
  @Output() nextMisSolicitudes = new EventEmitter<string>();

  checked: Boolean = false;
  avisoPrivacidad: Boolean = false;
  canEditRFC: Boolean = false;
  // Colonia
  filteredOptions: Observable<ITerritorio[]>;
  myControl = new FormControl();
  
  // Redvial
  filteredOptionsCalle: Observable<IRedVial[]>;
  myControlCalle = new FormControl();
  calle: IRedVial;

  // RedVial Cruce
  filteredOptionsCruce: Observable<IRedVial[]>;
  myControlCruce = new FormControl();
  cruce: IRedVial;
  frmCampos: FormGroup;
  
  // Giros
  filteredOptionsGiro: Observable<IGiro[]>;
  myControlGiro = new FormControl();
  
  constructor(private loginService: LoginService,
              private ss: SolicitudService,
              private mapaService: MapaService,
              private snackBar: MatSnackBar) { }

  async ngOnInit() {
    this.configurarValidadores();
    await this.configuraCatalogos();
    await this.initSolicitud();
    this.configurarAutocomplete();
    this.configurarAutocompleteGiro();
  }
  configurarValidadores() {
    this.frmCampos = new FormGroup({
      nombreComercial: new FormControl('', [Validators.required, Validators.minLength(5)]),
      aAntiguedad: new FormControl('', [Validators.required, Validators.minLength(1)]),
      numEmpleados: new FormControl('', [Validators.required, Validators.minLength(1)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      telefono: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      nExterior: new FormControl('', [Validators.required, Validators.minLength(1)]),
      representante_nombre: new FormControl('', [Validators.required, Validators.minLength(5)]),
      representante_email: new FormControl('', [Validators.required, Validators.email]),
      representante_telefono: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      enlace_nombre: new FormControl('', [Validators.required, Validators.minLength(5)]),
      enlace_email: new FormControl('', [Validators.required, Validators.email]),
      enlace_telefono: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
    });
  }
  async configuraCatalogos() {
    if(this.loginService.user.idgrupo !== '41334c8e-47fe-4705-a864-e27c3f651ca6') {
      this.canEditRFC = true;
    } else {
      this.canEditRFC = false;
    }
    this.ss.lstTipoSolicitante = await this.ss.GetTipoSolicitante();
    this.ss.lstRol = await this.ss.GetRol();
    this.ss.lstVerificacion = await this.ss.GetListaVerificacion();
    this.ss.lstGiro = await this.ss.GetGiro();

    const respColonias: IHttpResponse = await this.ss.GetColonia();
    if (respColonias.status === 200) {
      this.ss.lstColonia = respColonias.data;
    }

  }
  async initSolicitud() {
    await this.ss.resetSolicitud();
  }
  evalEnlaceSSA() {
    if(this.checked) {
      
      this.ss.solicitud.enlace_nombre = this.ss.solicitud.representante_nombre;
      this.ss.solicitud.enlace_telefono = this.ss.solicitud.representante_telefono;
      this.ss.solicitud.enlace_email = this.ss.solicitud.representante_email ;
    } else {
      this.ss.solicitud.enlace_nombre = '';
      this.ss.solicitud.enlace_telefono = '';
      this.ss.solicitud.enlace_email = '';
    }
  }
  // Autocomplete Colonia
  configurarAutocomplete() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filter(name) : this.ss.lstColonia.slice())
      );
  }
  displayFn(colonia?: ITerritorio): string | undefined {
    return colonia ? colonia.nombre : undefined;
  }
  private _filter(name: string): ITerritorio[] {
    const filterValue = name.toLowerCase();
    return this.ss.lstColonia.filter(option => option.nombre.toLowerCase().indexOf(filterValue) >= 0);
  }
  async autoCompleteColoniaChanged(colonia: ITerritorio) {
    try {
      let that = this;
      const respRedVial: IHttpResponse = await this.ss.GetRedVialByColonia(colonia.nombre);  
      if (respRedVial.status === 200) {
        this.ss.lstRedVial = respRedVial.data;
        this.configurarAutocompleteCalle();
      }
      // Resaltamos la colonia seleccionada
      this.mapaService.clearVectorLayer(this.mapaService.vectorLayerColonia);
      this.mapaService.addFeatureAndZoom(this.mapaService.vectorLayerColonia, colonia.geom);
    } catch (error) {
      console.log(error);
    }
    
  }
  // Autocomplete en redvial
  configurarAutocompleteCalle() {
    this.filteredOptionsCalle = this.myControlCalle.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.redvial),
        map(name => name ? this._filterCalle(name) : this.ss.lstRedVial.slice())
      );
  }
  displayFnCalle(redvial?: IRedVial): string | undefined {
    return redvial ? redvial.redvial : undefined;
  }
  private _filterCalle(name: string): IRedVial[] {
    const filterValue = name.toLowerCase();
    return this.ss.lstRedVial.filter(option => option.redvial.toLowerCase().indexOf(filterValue) >= 0);
  }
  async autoCompleteCalleChanged(redvial: IRedVial) {
    try {
      this.calle = redvial;
      const respRedVialCruce: IHttpResponse = await this.ss.GetRedVialCruce(redvial.redvial, redvial.geom);  
      if (respRedVialCruce.status === 200) {
        this.ss.lstRedVialCruce = respRedVialCruce.data;
        this.configurarAutocompleteCruce();
      }
      // Resaltamos la redvial seleccionada
      this.mapaService.clearVectorLayer(this.mapaService.vectorLayerRedVial);
      this.mapaService.addFeatureLayer(this.mapaService.vectorLayerRedVial, redvial.geom);
      
    } catch (error) {
      console.log(error);
    }
  }
  // Autocomplete en Cruce de Calles
  configurarAutocompleteCruce() {
    this.filteredOptionsCruce = this.myControlCruce.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.redvial),
        map(name => name ? this._filterCruce(name) : this.ss.lstRedVialCruce.slice())
      );
  }
  displayFnCruce(redvial?: IRedVial): string | undefined {
    return redvial ? redvial.redvial : undefined;
  }
  
  private _filterCruce(name: string): IRedVial[] {
    const filterValue = name.toLowerCase();
    return this.ss.lstRedVialCruce.filter(option => option.redvial.toLowerCase().indexOf(filterValue) >= 0);
  }

  async autoCompleteCruceChanged(redvial: IRedVial) {
    try {
      this.cruce = redvial;
      // Resaltamos la redvial seleccionada
      this.mapaService.clearVectorLayer(this.mapaService.vectorLayerRedVialCruce);
      this.mapaService.addFeatureLayer(this.mapaService.vectorLayerRedVialCruce, redvial.geom);
      const respCruce: IHttpResponse = await this.mapaService.GetIntersectionPointWithBuffer(this.calle.geom, this.cruce.geom, 5);
      
      if (respCruce.status === 200 && respCruce.data.length > 0) {
        this.mapaService.clearVectorLayer(this.mapaService.vectorLayerUbicacion);
        const jsonCoordinates = JSON.parse(respCruce.data[0].geom);
        this.ss.solicitud.latitud = jsonCoordinates.coordinates[1];
        this.ss.solicitud.longitud = jsonCoordinates.coordinates[0];
        this.mapaService.addFeatureLayer(this.mapaService.vectorLayerUbicacion, respCruce.data[0].geom);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // Autocomplete Giro
  configurarAutocompleteGiro() {
    this.filteredOptionsGiro = this.myControlGiro.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.giro),
        map(name => name ? this._filterGiro(name) : this.ss.lstGiro.slice())
      );
  }
  
  displayFnGiro(giro?: IGiro): string | undefined {
    return giro ? giro.giro : undefined;
  }

  private _filterGiro(name: string): IGiro[] {
    const filterValue = name.toLowerCase();
    return this.ss.lstGiro.filter(option => option.giro.toLowerCase().indexOf(filterValue) >= 0);
  }

  async geoLocalizarCalleAndNExterior() {
    if (this.calle && this.ss.solicitud.establecimiento_numero_exterior) {
      const respGeo: IHttpResponse = await this.mapaService.GetGeoReferenciaByCalleAndNexterior(this.calle.geom, this.ss.solicitud.establecimiento_numero_exterior);
      
      if (respGeo.status === 200 && respGeo.data.length > 0) {
        this.mapaService.clearVectorLayer(this.mapaService.vectorLayerUbicacion);
        const jsonCoordinates = JSON.parse(respGeo.data[0].geom);
        this.ss.solicitud.latitud = jsonCoordinates.coordinates[1];
        this.ss.solicitud.latitud = jsonCoordinates.coordinates[0];
        this.mapaService.addFeatureLayer(this.mapaService.vectorLayerUbicacion, respGeo.data[0].geom);
      }
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  async guardar() {
    try {
    const lstVerificacionAceptado: Array<IVerificacion> = this.ss.lstVerificacion.filter((verificacion: IVerificacion) => verificacion.selected);
    // Evaluamos si acepto terminos y condiciones
    if (!this.avisoPrivacidad) {
      this.snackBar.open('Debe aceptar el aviso de privacidad', 'X', {duration: 3000});
      return null;
    }
    // Evaluamos listado de verificacion
    else if (lstVerificacionAceptado.length !== this.ss.lstVerificacion.length) {
      this.snackBar.open('Debe haber cumplico con todos los requisitos del listado de Verificación Sanitaria', 'X', {duration: 3000});
      return null;
    } else {
      // guarda la solicitud
      if(typeof(this.ss.solicitud.establecimiento_colonia) !== "string") {
        this.ss.solicitud.establecimiento_colonia = this.ss.solicitud.establecimiento_colonia['nombre'];
      }
      if(typeof(this.ss.solicitud.establecimiento_calle) !== "string") {
        this.ss.solicitud.establecimiento_calle = this.ss.solicitud.establecimiento_calle['redvial'];
      }
      if(typeof(this.ss.solicitud.establecimiento_cruce) !== "string") {
        this.ss.solicitud.establecimiento_cruce = this.ss.solicitud.establecimiento_cruce['redvial'];
      }
      if(typeof(this.ss.solicitud.establecimiento_giro) !== "string") {
        this.ss.solicitud.establecimiento_giro = this.ss.solicitud.establecimiento_giro['giro'];
      }
      const resp: any = await this.ss.iniciarNuevaSolicitud(this.ss.solicitud);
      if(resp.status === 200) {
        this.snackBar.open('Solicitud guardada con éxito','X', {duration: 20000});
       this.nextMisSolicitudes.emit();
      } else {
        this.snackBar.open('No se pudo generar la solicitud. Favor de volverlo a intentar', 'X', {duration: 3000});
      }
       // Dialog
      // Event Emmiter para mandar a mis tramites
    }
  } catch(error) {
    this.snackBar.open('No se pudo generar la solicitud', 'X', {duration: 3000});
  } 
  }
}
