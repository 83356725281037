import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { VisorData } from 'src/app/components/visor/visor.component';
import { GrupoData } from 'src/app/components/grupo/grupo.component';

@Injectable({
  providedIn: 'root'
})
export class HerramientaService {

  constructor(private http: HttpClient) { }

  getHerramientas() {
    return this.http.get<any>(Constants.API_URL + '/seguridad/herramienta/getHerramientas').toPromise();
  }

  postHerramienta(herramienta: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/herramienta/postHerramienta`, herramienta);
  }

  editHerramienta(herramienta: any) {
    return this.http.put(`${Constants.API_URL}/seguridad/herramienta/editHerramienta`, herramienta);
  }

  deleteHerramienta(herramienta: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/herramienta/deleteHerramienta`, herramienta);
  }
  getPermisoHerramientasByVisorAndGrupo(visor: VisorData, grupo: GrupoData) {
    return this.http.get<any>(Constants.API_URL + '/seguridad/herramienta/getHerramientasPermisoByVisorAndGrupo?idvisor='+visor.idvisor+'&idgrupo='+grupo.idgrupo).toPromise();
  }
  asignaHerramientasVisorAndGrupo(grupo: any, visor: any, herramientas: any) {
    let body = {
      visor: visor,
      grupo: grupo,
      herramientas: herramientas,
    };
    return this.http.post(`${Constants.API_URL}/seguridad/herramienta/asignaHerramientasVisorAndGrupo`, body);
  }
}
