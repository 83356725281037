import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserData } from '../usuario.component';
import { GrupoService } from '../../../../services/grupo.service';

@Component({
  selector: 'app-dialog-usuario',
  templateUrl: './dialog-usuario.component.html',
  styleUrls: ['./dialog-usuario.component.css']
})
export class DialogUsuarioComponent implements OnInit {

  titulo = 'Nuevo usuario';
  frmCampos: FormGroup;
  usuario: UserData;
  lstGrupos: any;

  constructor( private dialogRef: MatDialogRef<DialogUsuarioComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,
               private grupoService: GrupoService
             ) { }

  async ngOnInit() {
    this.frmCampos = new FormGroup({
      login: new FormControl('', [Validators.required, Validators.minLength(1)]),
      password: new FormControl('', [Validators.required, Validators.minLength(1)]),
      nombre: new FormControl('', [Validators.required, Validators.minLength(1)]),
      apellidopaterno: new FormControl('', [Validators.required, Validators.minLength(1)]),
      apellidomaterno: new FormControl('', [Validators.required, Validators.minLength(1)]),
      email: new FormControl('', [Validators.required, Validators.email]),
     // telefono: new FormControl('', [Validators.required, Validators.minLength(1)]),
    });

    if (this.data.editar) {
      this.titulo = 'Editar usuario';
      this.usuario = this.data.usuario;
    } else {
      this.usuario = { idusuario: '', nombre: '', login: '', password: '', activo: true, apellidopaterno: '', apellidomaterno: '',
                       telefono: '', email: '', idgrupo: '', nombregrupo: ''};
    }
    
    this.lstGrupos = await this.grupoService.getGrupos();
    
  }
  cancelar() {
    this.dialogRef.close(undefined);
  }
  guardar() {
    this.dialogRef.close(this.usuario);
  }

}
