import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource, MatTabChangeEvent } from '@angular/material';
import { ICirculoCiudadano, IHttpResponse } from '../../../classes/interfaces';
import { CirculoCiudadanoService } from '../../../services/circulo-ciudadano.service';
import { DialogCapturaComponent } from './dialog-captura/dialog-captura.component';
import { DialogConfirmacionComponent } from '../dialogs/dialog-confirmacion/dialog-confirmacion.component';

@Component({
  selector: 'app-captura-ciudadano',
  templateUrl: './captura-ciudadano.component.html',
  styleUrls: ['./captura-ciudadano.component.css']
})
export class CapturaCiudadanoComponent implements OnInit {
  selectedTabIndex: number = 0;
  displayedColumnsCirculoCiudadano: string[] = ['nombrecompleto', 'domicilio', 'telefono', 'acciones'];
  dataSourceCirculoCiudadano: MatTableDataSource<ICirculoCiudadano>;
  circuloCiudadanoSelected: ICirculoCiudadano;  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 
  
  dataSourceVocal: MatTableDataSource<ICirculoCiudadano>;
  @ViewChild(MatPaginator) paginatorVocal: MatPaginator;
  @ViewChild(MatSort) sortVocal: MatSort;

  constructor(private circuloCiudadanoService: CirculoCiudadanoService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  async ngOnInit() {
    await this.configureCirculosCiudadanos();  
  }

  async configureCirculosCiudadanos() {
    const resp: IHttpResponse = await this.circuloCiudadanoService.GetCirculoCiudadano();
    if (resp.status === 200) {
      this.circuloCiudadanoService.lstCirculoCiudadano = resp.data;
    }
    
    if (!this.circuloCiudadanoSelected && this.circuloCiudadanoService.lstCirculoCiudadano.length > 0) {
        this.circuloCiudadanoSelected = this.circuloCiudadanoService.lstCirculoCiudadano[0];
    }

    this.dataSourceCirculoCiudadano = new MatTableDataSource(this.circuloCiudadanoService.lstCirculoCiudadano);
    this.dataSourceCirculoCiudadano.paginator = this.paginator;    
    this.dataSourceCirculoCiudadano.sort = this.sort;
    
    const respColonias: IHttpResponse = await this.circuloCiudadanoService.GetColonia();
    if (respColonias.status === 200) {
      this.circuloCiudadanoService.lstColonia = respColonias.data;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChangeEvent.index;
  }

  async agregarCiculoCiudadanoDialog() {
    const dialogo = this.dialog.open(DialogCapturaComponent, {
      height: '95%',
      width: '90%',
      disableClose: true,
      data: { editar: false,
              isCiudadano: true }
    });

    dialogo.afterClosed().subscribe(async circuloCiudadano => {
     
      if (circuloCiudadano) {
        try {
          const response:IHttpResponse = await this.circuloCiudadanoService.PostCirculoCiudadano(circuloCiudadano);
          await this.configureCirculosCiudadanos();
          if(response.status === 200) {
            this.snackBar.open('Los datos se guardaron exitosamente.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al guardar.', '', {
              duration: 5000,
            });
          }
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceCirculoCiudadano.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceCirculoCiudadano.paginator) {
      this.dataSourceCirculoCiudadano.paginator.firstPage();
    }
  }

  async editarCirculoCiudadanoDialog(circuloCiudadano: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogCapturaComponent, {
      height: '95%',
      width: '90%',
      disableClose: true,
      data: { editar: true,
              isCiudadano: true,
              ciudadano: circuloCiudadano }
    });

    dialogo.afterClosed().subscribe(async circuloCiudadano => {
     
      if (circuloCiudadano) {
        try {
          const response:IHttpResponse = await this.circuloCiudadanoService.PutCirculoCiudadano(circuloCiudadano);
          this.configureCirculosCiudadanos();
          if(response.status === 200) {
            this.snackBar.open('Los datos se guardaron exitosamente.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al guardar.', '', {
              duration: 5000,
            });
          }
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async eliminarCirculoCiudadano(circuloCiudadano: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogConfirmacionComponent, {
      height: '150px',
      width: '300px',
      disableClose: true,
      });

    dialogo.afterClosed().subscribe(async res => {
     
      if (res) {
        try {
          const response:IHttpResponse = await this.circuloCiudadanoService.DelCirculoCiudadano(circuloCiudadano);
          this.configureCirculosCiudadanos();
          if(response.status === 200) {
            this.snackBar.open('Circulo ciudadano eliminado con éxito.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al eliminar el circulo ciudadano.', '', {
              duration: 5000,
            });
          }
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al eliminar el circulo ciudadano.', '', {
            duration: 5000,
          });
        }
      }
    });
  }
  async openAsignarVocales(circuloCiudadano: ICirculoCiudadano) {
    this.selectedTabIndex = 1;
    this.circuloCiudadanoSelected = circuloCiudadano;
    await this.configureVocales();
  }
  async configureVocales() {
    const resp: IHttpResponse = await this.circuloCiudadanoService.GetVocal(this.circuloCiudadanoSelected.id);
    if (resp.status === 200) {
      this.circuloCiudadanoService.lstVocal = resp.data;
    }
    
    this.dataSourceVocal = new MatTableDataSource(this.circuloCiudadanoService.lstVocal);
    this.dataSourceVocal.paginator = this.paginatorVocal;
    this.dataSourceVocal.sort = this.sortVocal;
    
    const respColonias: IHttpResponse = await this.circuloCiudadanoService.GetColonia();
    if (respColonias.status === 200) {
      this.circuloCiudadanoService.lstColonia = respColonias.data;
    }
  }
  applyFilterVocal(filterValue: string) {
    this.dataSourceVocal.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceVocal.paginator) {
      this.dataSourceVocal.paginator.firstPage();
    }
  }

  async agregaVocalDialog(vocal: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogCapturaComponent, {
      height: '95%',
      width: '90%',
      disableClose: true,
      data: { editar: false,
              isCiudadano: false,
              ciudadano: this.circuloCiudadanoSelected }
    });

    dialogo.afterClosed().subscribe(async vocal => {
     
      if (vocal) {
        try {
          const response:IHttpResponse = await this.circuloCiudadanoService.PostVocal(vocal);
          if(response.status === 200) {
            this.snackBar.open('Los datos se guardaron exitosamente.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al guardar.', '', {
              duration: 5000,
            });
          }
          await this.configureVocales();
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async editarVocalDialog(vocal: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogCapturaComponent, {
      height: '95%',
      width: '90%',
      disableClose: true,
      data: { editar: true,
              isCiudadano: false,
              ciudadano: vocal }
    });

    dialogo.afterClosed().subscribe(async vocal => {
     
      if (vocal) {
        try {
          const response:IHttpResponse = await this.circuloCiudadanoService.PutVocal(vocal);
          if(response.status === 200) {
            this.snackBar.open('Los datos se guardaron exitosamente.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al guardar.', '', {
              duration: 5000,
            });
          }
          await this.configureVocales();
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async eliminarVocal(vocal: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogConfirmacionComponent, {
      height: '150px',
      width: '300px',
      disableClose: true,
      });

    dialogo.afterClosed().subscribe(async res => {
     
      if (res) {
        try {
          const response:IHttpResponse = await this.circuloCiudadanoService.DelVocal(vocal);
          await this.configureVocales();
          if(response.status === 200) {
            this.snackBar.open('Vocal eliminado con éxito.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al eliminar el Vocal.', '', {
              duration: 5000,
            });
          }
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al eliminar el Vocal.', '', {
            duration: 5000,
          });
        }
      }
    });
  }
}
