import { Component, OnInit, ViewChild } from '@angular/core';
import { VisorService } from '../../../services/visor.service';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource, MatTabChangeEvent } from '@angular/material';
import { DialogVisorComponent } from './dialog-visor/dialog-visor.component';
import { ServicioMapaData } from '../servicio-mapa/servicio-mapa.component';
import { ServicioMapaService } from 'src/services/servicio-mapa.service';
import {SelectionModel} from '@angular/cdk/collections';


export interface VisorData {
  idvisor: string,
  nombre: string, 
  descripcion: string,
}

@Component({
  selector: 'app-visor',
  templateUrl: './visor.component.html',
  styleUrls: ['./visor.component.css']
})
export class VisorComponent implements OnInit {
  lstVisores: any;
  displayedColumns: string[] = ['nombre', 'descripcion', 'acciones'];
  dataSource: MatTableDataSource<VisorData>;
  visorSelected: VisorData;
  
  // Servicios asignados a un visor 
  lstServicios: any;
  displayedColumnsServicio: string[] = ['select', 'nombre', 'url', 'servicio', 'tipo'];
  dataSourceServicio: MatTableDataSource<ServicioMapaData> = new MatTableDataSource([]);
  selection = new SelectionModel<ServicioMapaData>(true, []);
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 

  selectedTabIndex = 0;

  constructor(private visorService: VisorService,
              private servicioMapaService: ServicioMapaService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }


  
  async ngOnInit() 
  {
    this.configureVisores();
  }

  async configureVisores() {
    this.lstVisores = await this.visorService.getVisores();
    if (this.lstVisores.length > 0) {
      this.visorSelected = this.lstVisores[0];
    }
    this.dataSource = new MatTableDataSource(this.lstVisores);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.configureServicios();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChangeEvent.index;
  }
  async agregarVisorDialog() {
    
    const dialogo = this.dialog.open(DialogVisorComponent, {
      height: '420px',
      width: '480px',
      disableClose: true,
      data: { editar: false }
    });

    dialogo.afterClosed().subscribe(async visor => {
     
      if (visor) {
        try {
          const response = await this.visorService.postVisor(visor).toPromise();
          this.configureVisores();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
    
  }
  editarVisorDialog(visor: any) { 
    const dialogo = this.dialog.open(DialogVisorComponent, {
      height: '420px',
      width: '480px',
      disableClose: true,
      data: { visor: visor, editar: true }
    });

    dialogo.afterClosed().subscribe(async visor => {
     
      if (visor) {
        try {
          const response = await this.visorService.editVisor(visor).toPromise();
          this.configureVisores();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }
  async disableVisor(visor: any) {
    if (visor) {
      try {
        await this.visorService.disableVisor(visor).toPromise();
        this.configureVisores();

        this.snackBar.open('Los datos se guardaron exitosamente.', '', {
          duration: 5000,
        });
      } catch (error) {
        console.log(error);
        this.snackBar.open('Ocurrió un error al guardar.', '', {
          duration: 5000,
        });
      }
    }
  }
    // Tab Servicios de Mapas
    /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceServicio.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSourceServicio.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: ServicioMapaData): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idservicio}`;
  }
   
    openAsignarPermisosServicios(row: VisorData) {
      this.visorSelected = row;
      this.selectedTabIndex = 1;
      this.configureServicios();
    }
    
  async configureServicios() {
    this.lstServicios = await this.servicioMapaService.getServiciosPermisoByVisor(this.visorSelected);
    this.dataSourceServicio = new MatTableDataSource(this.lstServicios);

    this.selection = new SelectionModel<ServicioMapaData>(true, this.dataSourceServicio.data.filter(servicio => {
      return servicio.haspermiso;
    }));

  }

    async actualizarPermisoServicioVisor() {
      if (this.visorSelected) {
        await this.servicioMapaService.asignaServiciosVisor(this.visorSelected, this.selection.selected).toPromise();
        this.snackBar.open('Permisos actualizados con éxito', 'X', {
          duration: 3000
        });
      } else {
        this.snackBar.open('Debe tener seleccionado un visor','X', {
          duration: 3000
        });
      }
    }
}
