// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// local
/*
export const environment = {
  production: false,
  API_URL: 'http://localhost:3000/api',
  SERVER_API_URL: 'http://localhost:3000/api',
  FILES_URL: 'http://localhost:3000/folios/',
  PHOTO_URL: 'http://localhost:3001/photo/',
  ROOT_URL: 'https://reactivacioneconomica.tlajomulco.gob.mx',
  IS_SERVER: false,
  IS_ADMIN : '41334c8e-47fe-4705-a864-e27c3f651ca6'
};
*/
// Production

export const environment = {
  production: false,
  API_URL: 'https://appreactivacioneconomica.tlajomulco.gob.mx/api',
  SERVER_API_URL: 'https://appreactivacioneconomica.tlajomulco.gob.mx/api',
  FILES_URL: 'https://appreactivacioneconomica.tlajomulco.gob.mx/folios/',
  PHOTO_URL: 'https://appreactivacioneconomica.tlajomulco.gob.mx//photo/',
  ROOT_URL: 'https://reactivacioneconomica.tlajomulco.gob.mx',
  IS_SERVER: false,
  IS_ADMIN : '41334c8e-47fe-4705-a864-e27c3f651ca6'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
