import { Component, OnInit } from '@angular/core';
import { MapaService } from '../../../services/mapa.service';
import { View, Tile } from 'ol';
import * as proj from 'ol/proj';
import * as control from 'ol/control';
import * as interaction from 'ol/interaction';
import * as source from 'ol/source';
import * as layer from 'ol/layer';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {

  constructor(private mapaService: MapaService) { }

  ngOnInit() {
    this.initSetup();
    // Configuracion inicial de los mapas
    setTimeout(() => {
      this.mapaService.addVectorLayer(this.mapaService.vectorLayerColonia);
      this.mapaService.addVectorLayer(this.mapaService.vectorLayerRedVial);
      this.mapaService.addVectorLayer(this.mapaService.vectorLayerRedVialCruce);
      this.mapaService.addVectorLayer(this.mapaService.vectorLayerUbicacion);
    }, 3000);
  }

  initSetup() {
    this.mapaService.createProjection();
    const view = this.mapaService.createView();
    this.mapaService.initMap({
      layers: [new layer.Tile({source: new source.OSM()})],
      target: 'mapa-container',
      interactions: interaction.defaults({
        altShiftDragRotate: false,
        pinchRotate: false
      }),
      controls: control.defaults({
        zoom: false,
        rotate: false,
        attribution: false,
        attributionOptions: /** @type {olx.control.AttributionOptions} */ ({
          collapsible: false
        })
      }),
      view: view
    });
  }

}
