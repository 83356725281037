import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { ISolicitud, IHttpResponse, ITipo, IRol, 
         ITerritorio, IRedVial, IVerificacion, IGiro } from '../classes/interfaces';

import { LoginService } from './login.service';
@Injectable({
  providedIn: 'root'
})
export class SolicitudService {
  solicitud: ISolicitud = {
    id : 'nueva',
    folio: 'nueva',
    idusuario: this.loginService.user.idusuario,
    idtiposolicitante:'',
  };
  lstSolicitudes: Array<ISolicitud> = new Array<ISolicitud>(0);
  lstTipoSolicitante: Array<ITipo> = new Array<ITipo>(0);
  lstRol: Array<IRol> = new Array<IRol>(0);
  // Espaciales
  lstColonia: Array<ITerritorio> = new Array<ITerritorio>(0);
  lstRedVial: Array<IRedVial> = new Array<IRedVial>(0);
  lstRedVialCruce: Array<IRedVial> = new Array<IRedVial>(0);
  lstVerificacion: Array<IVerificacion> = new Array<IVerificacion>(0);
  lstGiro: Array<IGiro> = new Array<IGiro>(0);
  lstAnos: Array<string> = ['2020', '2019', '2018', '2017', '2016', '2015',
                            '2014', '2013', '2012', '2011', '2010', '2009',
                            '2008', '2007', '2006', '2005', '2004', '2003',
                            '2002', '2001', '2000', '1999', '1998', '1997',
                            '1998', '1997', '1996', '1995', '1994', '1993'];
  constructor(private http: HttpClient,
              private loginService: LoginService) { 
  }


  resetSolicitud() {
    this.solicitud = {
      id : 'nueva',
      folio: 'nueva',
      establecimiento_rfc: (this.loginService.user.idgrupo === '41334c8e-47fe-4705-a864-e27c3f651ca6') ? '' : this.loginService.user.login,
      idusuario: this.loginService.user.idusuario,
      idtiposolicitante: (this.lstTipoSolicitante.length > 0) ? this.lstTipoSolicitante[0].id : '',
      representante_idrol: (this.lstRol.length > 0) ? this.lstRol[0].id : ''
    };
  }

  iniciarNuevaSolicitud(solicitud: ISolicitud) {
    return this.http.post<IHttpResponse[]>(Constants.API_URL + '/solicitud/generaNuevaSolicitud', solicitud).toPromise();
  }
  
  getSolicitudes(idusuario: string) {
    return this.http.get<IHttpResponse[]>(Constants.API_URL + '/solicitud/getSolicitudes/'+ this.loginService.user.idusuario).toPromise();
  }
  postUpload(solicitud: ISolicitud, data: any) {
    return this.http.post<any>(`${Constants.API_URL}/file/uploadFiles?idsolicitud=`+solicitud.id, data).toPromise();
  }
  GetColonia() {
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/catalogo/espacial/GetColonia`).toPromise();
  }

  GetRedVialByColonia(colonia: string){
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/catalogo/GetRedVialByColonia/${colonia}`).toPromise();
  }

  GetRedVialCruce(nombreRedVial: string, geom: string) {
    const body = {
      nombre: nombreRedVial,
      geometry: geom
    };
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/catalogo/GetRedVialCruce`, body).toPromise();
  }
 
  GetTipoSolicitante() {
    return this.http.get<any>(`${Constants.API_URL}/catalogo/conOrden/tipo_solicitante/orden`).toPromise();
  }
  
  GetRol() {
    return this.http.get<any>(`${Constants.API_URL}/catalogo/conOrden/rol/orden`).toPromise();
  }
  GetListaVerificacion() {
    return this.http.get<any>(`${Constants.API_URL}/catalogo/conOrden/requisito/orden`).toPromise();
  }
  GetGiro() {
    return this.http.get<any>(`${Constants.API_URL}/catalogo/conOrden/giro/giro`).toPromise();
  }
}
