import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource, MatTabChangeEvent } from '@angular/material';
import { ICirculoCiudadano, IHttpResponse } from '../../../classes/interfaces';
import { CapturaCovidService } from '../../../services/captura-covid.service';
import { DialogCovidComponent } from './dialog-covid/dialog-covid.component';
import { DialogResponsableComponent } from './dialog-responsable/dialog-responsable.component';
import { DialogConfirmacionComponent } from '../dialogs/dialog-confirmacion/dialog-confirmacion.component';
import { Constants } from '../../../commons/constants';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-captura-covid',
  templateUrl: './captura-covid.component.html',
  styleUrls: ['./captura-covid.component.css']
})
export class CapturaCovidComponent implements OnInit {
  selectedTabIndex: number = 0;
  displayedColumnsResponsable: string[] = ['nombrecompleto', 'zona', 'numbeneficiarios', 'numeroentrega', 'acciones'];
  displayedColumnsBeneficiario: string[] = ['nombrecompleto', 'domicilio', 'telefono', 'acciones'];

  dataSourceResponsable: MatTableDataSource<ICirculoCiudadano>;
  responsableSelected: ICirculoCiudadano;  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 
  
  dataSourceBeneficiario: MatTableDataSource<ICirculoCiudadano>;
  @ViewChild(MatPaginator) paginatorVocal: MatPaginator;
  @ViewChild(MatSort) sortVocal: MatSort;
  numBeneficiarios = 0;
  // reportes
  fechainicio: any;
  fechafin: any;
  zona = '';
  constructor(private covidService: CapturaCovidService,
              private dialog: MatDialog,
              private loginService: LoginService,
              private snackBar: MatSnackBar) { }

  async ngOnInit() {
    await this.configureCirculosCiudadanos();  
  }


  async configureCirculosCiudadanos() {
    const resp: IHttpResponse = await this.covidService.GetResponsable();
    if (resp.status === 200) {
      this.covidService.lstResponsable = resp.data;
     
    }
    
    if (!this.responsableSelected && this.covidService.lstResponsable.length > 0) {
        this.responsableSelected = this.covidService.lstResponsable[0];
    }
    this.numBeneficiarios = 0;
    this.covidService.lstResponsable.map((responsable) => {
      this.numBeneficiarios = this.numBeneficiarios + parseInt(responsable.numbeneficiarios);
    });

    this.dataSourceResponsable = new MatTableDataSource(this.covidService.lstResponsable);
    this.dataSourceResponsable.paginator = this.paginator;    
    this.dataSourceResponsable.sort = this.sort;
    
    const respColonias: IHttpResponse = await this.covidService.GetColonia();
    if (respColonias.status === 200) {
      this.covidService.lstColonia = respColonias.data;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChangeEvent.index;
  }

  async agregarCiculoCiudadanoDialog() {
    const dialogo = this.dialog.open(DialogResponsableComponent, {
      height: '95%',
      width: '90%',
      disableClose: true,
      data: { editar: false,
              isCiudadano: true }
    });

    dialogo.afterClosed().subscribe(async circuloCiudadano => {
     
      if (circuloCiudadano) {
        try {
          const response:IHttpResponse = await this.covidService.PostResponsable(circuloCiudadano);
          await this.configureCirculosCiudadanos();
          if(response.status === 200) {
            this.snackBar.open('Los datos se guardaron exitosamente.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al guardar.', '', {
              duration: 5000,
            });
          }
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSourceResponsable.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceResponsable.paginator) {
      this.dataSourceResponsable.paginator.firstPage();
    }
  }

  async editarCirculoCiudadanoDialog(circuloCiudadano: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogResponsableComponent, {
      height: '95%',
      width: '90%',
      disableClose: true,
      data: { editar: true,
              isCiudadano: true,
              ciudadano: circuloCiudadano }
    });

    dialogo.afterClosed().subscribe(async circuloCiudadano => {
     
      if (circuloCiudadano) {
        try {
          const response:IHttpResponse = await this.covidService.PutResponsable(circuloCiudadano);
          this.configureCirculosCiudadanos();
          if(response.status === 200) {
            this.snackBar.open('Los datos se guardaron exitosamente.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al guardar.', '', {
              duration: 5000,
            });
          }
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async eliminarCirculoCiudadano(circuloCiudadano: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogConfirmacionComponent, {
      height: '150px',
      width: '300px',
      disableClose: true,
      });

    dialogo.afterClosed().subscribe(async res => {
     
      if (res) {
        try {
          const response:IHttpResponse = await this.covidService.DelResponsable(circuloCiudadano);
          this.configureCirculosCiudadanos();
          if(response.status === 200) {
            this.snackBar.open('Responsable eliminado con éxito.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al eliminar el Responsable.', '', {
              duration: 5000,
            });
          }
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al eliminar el Responsable.', '', {
            duration: 5000,
          });
        }
      }
    });
  }
  async openAsignarVocales(circuloCiudadano: ICirculoCiudadano) {
    this.selectedTabIndex = 1;
    this.responsableSelected = circuloCiudadano;
    await this.configureVocales();
  }
  async configureVocales() {
    const resp: IHttpResponse = await this.covidService.GetBeneficiario(this.responsableSelected.id);
    if (resp.status === 200) {
      this.covidService.lstBeneficiario = resp.data;
    }
    
    this.dataSourceBeneficiario = new MatTableDataSource(this.covidService.lstBeneficiario);
    this.dataSourceBeneficiario.paginator = this.paginatorVocal;
    this.dataSourceBeneficiario.sort = this.sortVocal;
    
    const respColonias: IHttpResponse = await this.covidService.GetColonia();
    if (respColonias.status === 200) {
      this.covidService.lstColonia = respColonias.data;
    }
  }
  applyFilterVocal(filterValue: string) {
    this.dataSourceBeneficiario.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceBeneficiario.paginator) {
      this.dataSourceBeneficiario.paginator.firstPage();
    }
  }

  async agregaVocalDialog(vocal: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogCovidComponent, {
      height: '95%',
      width: '90%',
      disableClose: true,
      data: { editar: false,
              isCiudadano: false,
              ciudadano: this.responsableSelected }
    });

    dialogo.afterClosed().subscribe(async vocal => {
     
      if (vocal) {
        try {
          const response:IHttpResponse = await this.covidService.PostBeneficiario(vocal);
          if(response.status === 200) {
            this.snackBar.open('Los datos se guardaron exitosamente.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al guardar.', '', {
              duration: 5000,
            });
          }
          await this.configureVocales();
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async editarVocalDialog(vocal: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogCovidComponent, {
      height: '95%',
      width: '90%',
      disableClose: true,
      data: { editar: true,
              isCiudadano: false,
              ciudadano: vocal }
    });

    dialogo.afterClosed().subscribe(async vocal => {
     
      if (vocal) {
        try {
          const response:IHttpResponse = await this.covidService.PutBeneficiario(vocal);
          if(response.status === 200) {
            this.snackBar.open('Los datos se guardaron exitosamente.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al guardar.', '', {
              duration: 5000,
            });
          }
          await this.configureVocales();
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async eliminarVocal(vocal: ICirculoCiudadano) {
    const dialogo = this.dialog.open(DialogConfirmacionComponent, {
      height: '150px',
      width: '300px',
      disableClose: true,
      });

    dialogo.afterClosed().subscribe(async res => {
     
      if (res) {
        try {
          const response:IHttpResponse = await this.covidService.DelBeneficiario(vocal);
          await this.configureVocales();
          if(response.status === 200) {
            this.snackBar.open('Beneficiario eliminado con éxito.', '', {
              duration: 5000,
            });
          } else {
            this.snackBar.open('Ocurrió un error al eliminar el Beneficiario.', '', {
              duration: 5000,
            });
          }
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al eliminar el Beneficiario.', '', {
            duration: 5000,
          });
        }
      }
    });
  }
  async generaCorte() {
    // Configuracmos las fechas
    let strFechaInicio = '';
    let strFechaFin = '';
    if (this.fechainicio) {
     strFechaInicio = this.fechainicio.getFullYear() + '-' + parseInt(this.fechainicio.getMonth()+1) + '-' +  this.fechainicio.getDate();
     strFechaFin = this.fechainicio.getFullYear() + '-' +  parseInt(this.fechainicio.getMonth()+1)+ '-' +  this.fechainicio.getDate();
    }

    if (this.fechafin) {
      strFechaFin = this.fechafin.getFullYear() + '-' + parseInt(this.fechafin.getMonth()+1) + '-' +  this.fechafin.getDate();
    
    }
    let resp = await this.covidService.GetCorteBeneficiariosCovid(strFechaInicio, strFechaFin, this.zona);
    if (resp.status === 200) {
      window.open(Constants.FILES_URL + '/' + resp.data, '_blank');
    } else {
      this.snackBar.open('Hubo un error al generar el reporte. Favor de volverlo a intentar', 'X', {duration: 3000});
    }
  }
}
