import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  user: {
    idusuario: string,
    idgrupo: string,
    login: string,
    password: string,
    apellidopaterno: string,
    apellidomaterno: string,
    nombre: string,
    telefono: string, 
    email: string,
    activo: boolean
  }
  isServerAlive: Boolean = false;
  password: string;
  constructor(private http: HttpClient) { 
    this.resetUser();
  }
  
  logOut() {
     this.resetUser();
     window.location.href = '#';
  }

  async login (login: string, password: string) {
    this.resetUser();
    this.user.login = login;
    this.user.password = password;  
    this.password = password;
    const resp: any = await this.getUsuario();
    this.user = resp;
    return resp;
  }

  getUsuario() {
    return this.http.post<any[]>(`${Constants.API_URL}/seguridad/usuario/autentica`, this.user).toPromise();
  }
  IsServerAlive() {
    return this.http.get<any[]>(`${Constants.SERVER_API_URL}/server/isAlive`).toPromise();
  }
  postUsuario(email: string, rfc: string, password: string) {
    this.resetUser();
    this.user.login = rfc;
    this.user.password = password;
    this.user.email = email;
    return this.http.post(`${Constants.API_URL}/seguridad/usuario/postUsuario`, this.user).toPromise();
  }
  resetUser() {
    this.user = {
      idusuario: '',
      idgrupo: '',
      login: '',
      password: '',
      apellidopaterno: '',
      apellidomaterno: '',
      nombre: '',
      telefono: '', 
      email: '',
      activo: false
    }
  }

}
