import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  constructor(private http: HttpClient) { }
  
  getGradoEstudios() {
    return this.http.get<any[]>(Constants.API_URL + '/catalogo/getGradoEstudios').toPromise();
  }

  getEstadoCivil() {
    return this.http.get<any[]>(Constants.API_URL + '/catalogo/getEstadoCivil').toPromise();
  }

  getBola() {
    return this.http.get<any[]>(Constants.API_URL + '/catalogo/getBola').toPromise();
  }
  
  getOcupacion() {
    return this.http.get<any[]>(Constants.API_URL + '/catalogo/getOcupacion').toPromise();
  }  

  getGenero() {
    return this.http.get<any[]>(Constants.API_URL + '/catalogo/getGenero').toPromise();
  } 

  getPais() {
    return this.http.get<any[]>(Constants.API_URL + '/catalogo/getPais').toPromise();
  } 

  getEstado(idpais: string) {
    return this.http.get<any[]>(Constants.API_URL + '/catalogo/getEstado?idpais=' + idpais).toPromise();
  }

  getMunicipio(idestado: string) {
    return this.http.get<any[]>(Constants.API_URL + '/catalogo/getMunicipio?idestado=' + idestado).toPromise();
  } 
  getCiudadanos() {
    return this.http.get<any[]>(Constants.API_URL + '/registro/getCiudadanos').toPromise();
  }
  postCiudadano(ciudadano: any) {
    return this.http.post<any>(Constants.API_URL + '/registro/postCiudadano', { ciudadano: ciudadano}).toPromise();
  }

  imprimeCiudadano(ciudadano: any) {
    return this.http.post<any>(Constants.API_URL + '/reporte/generaConfirmacionRecluta', { ciudadano: ciudadano}).toPromise();
  }
}
