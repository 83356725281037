import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource, MatTabChangeEvent } from '@angular/material';
import { ServicioMapaService } from 'src/services/servicio-mapa.service';
import { DialogServicioMapaComponent } from './dialog-servicio-mapa/dialog-servicio-mapa.component';


export interface ServicioMapaData {
  idservicio: string,
  nombre: string, 
  tipo: string,
  url: string,
  servicio: string,
  opacidad: Number,
  visible: Boolean,
  fechamodificacion?: string,
  
  
  
  haspermiso?: Boolean,
}

@Component({
  selector: 'app-servicio-mapa',
  templateUrl: './servicio-mapa.component.html',
  styleUrls: ['./servicio-mapa.component.css']
})

export class ServicioMapaComponent implements OnInit {
  selectedTabIndex: number = 0;
  lstServicioMapa: any = [];
  lstCapasGeobase: any = [];
  lstCapasServicio: any = [];
  displayedColumns: string[] = ['nombre', 'url', 'servicio', 'acciones'];
  dataSource: MatTableDataSource<ServicioMapaData>;
  servicioMapaSelected: ServicioMapaData = { idservicio: '', nombre: '', tipo: '', url: '', servicio: '', opacidad: 1, visible: true};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 

  @ViewChild('capasGeobase') capasGeobase: any;
  @ViewChild('capasServicio') capasServicio: any; 

  constructor(private servicioMapaService: ServicioMapaService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  async ngOnInit() {
    this.configureServiciosMapa();
    this.lstCapasGeobase = await this.servicioMapaService.getCapasGeobase();
  }
  async configureServiciosMapa() {
    this.lstServicioMapa = await this.servicioMapaService.getServicioMapas();
    if (this.lstServicioMapa.length > 0) {
      this.servicioMapaSelected = this.lstServicioMapa[0];
    }
    this.dataSource = new MatTableDataSource(this.lstServicioMapa);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChangeEvent.index;
  }

  async agregarServicioMapaDialog() {
    
    const dialogo = this.dialog.open(DialogServicioMapaComponent, {
      height: '620px',
      width: '480px',
      disableClose: true,
      data: { editar: false }
    });

    dialogo.afterClosed().subscribe(async servicio => {
     
      if (servicio) {
        try {
          const response = await this.servicioMapaService.postServicioMapa(servicio).toPromise();
          this.configureServiciosMapa();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }
  editarServicioMapaDialog(servicio: any) { 
    const dialogo = this.dialog.open(DialogServicioMapaComponent, {
      height: '620px',
      width: '480px',
      disableClose: true,
      data: { servicio: servicio, editar: true }
    });

    dialogo.afterClosed().subscribe(async servicio => {
     
      if (servicio) {
        try {
          const response = await this.servicioMapaService.editServicioMapa(servicio).toPromise();
          this.configureServiciosMapa();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }
  async openAsignarCapas(row: ServicioMapaData) {
    this.servicioMapaSelected = row;
    this.selectedTabIndex = 1;
    this.lstCapasServicio = await this.servicioMapaService.getCapasPorServicio(this.servicioMapaSelected).toPromise();
    this.configureCapasGeobase();
  }

  async configureCapasGeobase() {
    this.lstCapasGeobase = await this.servicioMapaService.getCapasGeobase();
    for(let i = this.lstCapasGeobase.length-1; i >= 0; i--) {
      let remover = false;
      this.lstCapasServicio.map(capaServicio => {
        if(this.lstCapasGeobase[i].nombre == capaServicio.nombre) {
          remover = true;
        }
      });

      if(remover) {
        this.lstCapasGeobase.splice(i, 1);
      }
    }
  }
  
  async asignarCapasServicio() {
    // Agregamos al listado de capas asignadas al servicio
    this.capasGeobase.selectedOptions.selected.map(capaGeobase => {
      this.lstCapasServicio.push(capaGeobase.value);
    });

    // Quitamos las capas del listado de capas de la geobase
   for(let i = this.lstCapasGeobase.length-1; i >= 0; i--) {
      let remover = false;
      this.lstCapasServicio.map(capaServicio => {
        if(this.lstCapasGeobase[i].nombre == capaServicio.nombre) {
          remover = true;
        }
      });

      if(remover) {
        this.lstCapasGeobase.splice(i, 1);
      }
    }
    
  }
 
  async quitarCapasServicio() {
    this.capasServicio.selectedOptions.selected.map(capaServicio => {
      this.lstCapasGeobase.push(capaServicio.value);
    });
    for(let i = this.lstCapasServicio.length-1; i >= 0; i--) {
      let remover = false;
      this.capasServicio.selectedOptions.selected.map(capaServicio => {
        if(this.lstCapasServicio[i].nombre === capaServicio.value.nombre) {
          remover = true;
        }
      });
      if(remover) {
        this.lstCapasServicio.splice(i, 1);
      }
    }
  }
  async actualizarCapasServicio() {
    try {
        if (this.lstCapasServicio.length > 0) {
          await this.servicioMapaService.asignaCapasServicio(this.servicioMapaSelected, this.lstCapasServicio).toPromise();
          this.snackBar.open('Capas asignadas al servicio de manera exitosa', '', {
            duration: 5000
          }); 
        } else {
        this.snackBar.open('Debes tener almenos una capa asignada al servicio', '', {
          duration: 5000
        }); 
        }
    } catch(error) {
      console.log(error);
      this.snackBar.open('Hubo un error al momento de actualizar', '', {
        duration: 5000
      }); 
    }
    
  }
}
