import { Injectable, EventEmitter } from '@angular/core';
import {Map, View} from 'ol';
import { Interaction } from 'ol/interaction'
import Projection from 'ol/proj/Projection';

import * as proj from 'ol/proj';
import * as layer from 'ol/layer';
import * as style from 'ol/style';
import * as geom from 'ol/geom';
import * as source from 'ol/source';
import * as format from 'ol/format';
import * as cuid from 'cuid';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { IHttpResponse } from '../classes/interfaces';

export enum MapEventType {
  CHANGE,
  CHANGE_LAYERGROUP,
  CHANGE_SIZE,
  CHANGE_TARGET,
  CHANGE_VIEW,
  CLICK,
  DOUBLECLICK,
  MOVEEND,
  POINTERDRAG,
  POINTERMOVE,
  POSTCOMPOSE,
  POSTRENDER,
  PRECOMPOSE,
  PROPERTYCHANGE,
  SINGLECLICK
}

const MapEvents: string[] = [
  'change', 'change:layerGroup', 'change:size', 'change:target', 'change:view', 'click', 'dblclick',
  'moveend', 'pointerdrag', 'pointermove', 'postcompose', 'postrender', 'precompose', 'propertychange', 'singleclick'
];

@Injectable({
  providedIn: 'root'
})
export class MapaService {
  mapa: Map;
  mapa_id: string;
  view: View;
  mapLoadedEvent: EventEmitter<any> = new EventEmitter<any>();
  projection: proj.ProjectionLike;
  pointTlajomulco: geom.Point = new geom.Point([-103.42291, 20.49252]);
  
  styleColonia: style.Style = new style.Style({
    stroke: new style.Stroke({
      color: 'rgba(0,0,0,1)',
      width: 2
    }),
    fill: new style.Fill({
      color: 'rgba(255,175,66,0.4)',
    }),
    image: new style.Circle({
      radius: 5,
      stroke: new style.Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new style.Fill({
        color: 'rgba(255, 152, 0, 0.7)'
      })
    })
  });
  
  styleRedVial: style.Style = new style.Style({
    stroke: new style.Stroke({
      color: 'rgba(255,255,0,1)',
      width: 8
    }),
    fill: new style.Fill({
      color: 'rgba(255,152,0,0.4)',
    }),
    image: new style.Circle({
      radius: 5,
      stroke: new style.Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new style.Fill({
        color: 'rgba(255, 152, 0, 0.7)'
      })
    })
  });
  
  styleRedVialCruce: style.Style = new style.Style({
    stroke: new style.Stroke({
      color: 'rgba(0,0,255,1)',
      width: 8
    }),
    fill: new style.Fill({
      color: 'rgba(255,152,0,0.4)',
    }),
    image: new style.Circle({
      radius: 5,
      stroke: new style.Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new style.Fill({
        color: 'rgba(255, 152, 0, 0.7)'
      })
    })
  });

  styleUbicacion: style.Style = new style.Style({
    stroke: new style.Stroke({
      color: 'rgba(255,0,0,1)',
      width: 8
    }),
    fill: new style.Fill({
      color: 'rgba(255,152,0,0.4)',
    }),
    image: new style.Circle({
      radius: 5,
      stroke: new style.Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new style.Fill({
        color: 'rgba(255, 152, 0, 0.7)'
      })
    })
  });

  vectorLayerColonia: layer.Vector;
  vectorLayerRedVial: layer.Vector;
  vectorLayerRedVialCruce: layer.Vector;
  vectorLayerUbicacion: layer.Vector;

  private eventHandlers: any = {};
  constructor(private http: HttpClient) { 
    const self = this;
    MapEvents.forEach(x => { self.eventHandlers[x] = []; });
    this.configureLayers();

  }
  configureLayers() {
    this.vectorLayerColonia = this.createVectorLayerWithStyle(this.styleColonia);
    this.vectorLayerRedVial = this.createVectorLayerWithStyle(this.styleRedVial);
    this.vectorLayerRedVialCruce = this.createVectorLayerWithStyle(this.styleRedVialCruce);
    this.vectorLayerUbicacion = this.createVectorLayerWithStyle(this.styleUbicacion);
  }
  createMap(options: any): Map {
    try {
      this.mapa = new Map(options);
      return this.mapa;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  createProjection(): proj.ProjectionLike {
    try {
      this.projection = new Projection({
        code: 'EPSG:4326',
         units: 'degrees',
        axisOrientation: 'neu',
        extent: [-180, -85, 180, 85],
        global: true
      });
      return this.projection;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  createView(): View {
    try {
      this.view = new View({
        center: [-103.42291, 20.49252],
        zoom: 12,
        projection: this.projection
      });
      return this.view;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  addLayer(l: layer.Layer) {
    if (this.mapa) {
      this.mapa.addLayer(l);
    }
  }
  mapLoaded() {
    setTimeout(() => {
      this.mapLoadedEvent.emit();
    }, 4000);
  }
  getView() {
    return this.mapa.getView();
  }
  // Acciones en el mapa
  centerToPoint(point: geom.Point) {
    this.view.fit(point);
  }
  centerAndZoom(zoomLevel: number, point: geom.Point) {
    this.view.animate({zoom: zoomLevel}, {center: [point.getCoordinates()[0], point.getCoordinates()[1]]});
  }
  // Metodos para gestionar VectorLayers sobre el mapa
  createVectorLayer() {
    const vectorSource = new source.Vector();
    const vector = new layer.Vector({
      opacity: 0.9999989,
      source: vectorSource,
      style: new style.Style({
        stroke: new style.Stroke({
          color: 'rgba(255,152,0,1)',
          width: 2
        }),
        fill: new style.Fill({
          color: 'rgba(255,152,0,0.6)',
        }),
        image: new style.Circle({
          radius: 5,
          stroke: new style.Stroke({
            color: 'rgba(0, 0, 0, 0.7)'
          }),
          fill: new style.Fill({
            color: 'rgba(255, 152, 0, 0.7)'
          })
        })
      })
    });
    return vector;
  }
  createVectorLayerWithStyle(style: style.Style) {
    const vectorSource = new source.Vector();
    const vector = new layer.Vector({
      opacity: 0.99999891,
      source: vectorSource,
      style: style
    });
    return vector;
  }

  addVectorLayer(vectorLayer: layer.Vector) {
    this.mapa.addLayer(vectorLayer);
  }
  removeVectorLayer(vectorLayer: layer.Vector) {
    this.mapa.removeLayer(vectorLayer);
  }
  
  clearVectorLayer(vectorLayer: layer.Vector) {
    vectorLayer.getSource().clear();
  }

  addFeatureLayer(vectorLayer: layer.Vector, geojson: any) {
    const feature = new format.GeoJSON().readFeature(geojson);
    vectorLayer.getSource().addFeature(feature);
  }
  addFeatureAndZoom(vectorLayer: layer.Vector, geojson: any) {
    const feature = new format.GeoJSON().readFeature(geojson);
    vectorLayer.getSource().addFeature(feature);
    this.view.fit(vectorLayer.getSource().getExtent());
  }
  // Interactions
  removeAllInteractions() {
    console.log(this.mapa.getInteractions());
    while (this.mapa.getInteractions().getLength() > 0) {
      this.mapa.getInteractions().pop();
    }
  }

  addInteraction(inter: Interaction) {
    if (this.mapa) {
      this.mapa.addInteraction(inter);
    }
  }

  removeInteraction(inter: Interaction) {
    if (this.mapa) {
      this.mapa.removeInteraction(inter);
    }
  }
  
  addEventHandler(eventType: MapEventType, handler: (...args: any[]) => void, priority = false): string {
    if (!this.eventHandlers[MapEvents[eventType]]) {
      this.eventHandlers[MapEvents[eventType]] = [];
    }

    const key = MapEvents[eventType] + '.' + cuid();
    const eventHandler = {
      key: key,
      priority: priority,
      fn: handler
    };

    this.eventHandlers[MapEvents[eventType]] = [...this.eventHandlers[MapEvents[eventType]], eventHandler];

    return key;
  }

  removeEventHandler(id: string) {
    try {
      const evtType = id.split('.')[0];
      const idx = this.eventHandlers[evtType].reduce((a, b, i) => (b.key === id ? i : a), -1);
  
      if (idx === -1) {
        return;
      }
  
      this.eventHandlers[evtType] = [
        ...this.eventHandlers[evtType].slice(0, idx),
        ...this.eventHandlers[evtType].slice(idx + 1)
      ];  
    } catch {
      console.log('id null');
    }
  }

  initMap(options: any): void {
    const self = this;
    try {
      self.mapa = new Map(options);
      self.mapa.on(MapEvents, (evt) => {
        !!self.eventHandlers[evt.type] && self.eventHandlers[evt.type].some(x => x.priority)
          ? self.eventHandlers[evt.type].filter(x => x.priority).forEach(x => {
            x.fn(evt);
          })
          : self.eventHandlers[evt.type].forEach(x => {
            x.fn(evt);
          });
      });
      self.mapa_id = 'mapa.' + cuid();

      self.mapa.on('moveend', () => { self.mapa.render(); });
    } catch (e) {
      console.log(e);
    }
  }

  GetIntersectionPoint(geometry1: any, geometry2: any) {
    const body = {
      geometry1: geometry1,
      geometry2: geometry2
    };
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/geoproceso/GetIntersectionPoint`, body).toPromise();
  }
  GetIntersectionPointWithBuffer(geometry1: any, geometry2: any, buffer: number) {
    const body = {
      geometry1: geometry1,
      geometry2: geometry2,
      buffer: buffer
    };
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/geoproceso/GetIntersectionPointWithBuffer`, body).toPromise();
  }
  GetGeoReferenciaByCalleAndNexterior(geometry: any, nexterior: string) {
    const body = {
      geometry: geometry,
      nexterior: nexterior
    };
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/geoproceso/GetGeoReferenciaByCalleAndNexterior`, body).toPromise();
  }
}
