import { Component, OnInit, ViewChild } from '@angular/core';
import { VisorService } from '../../../services/visor.service';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DialogHerramientaComponent } from './dialog-herramienta/dialog-herramienta.component';
import { HerramientaService } from 'src/services/herramienta.service';


export interface HerramientaData {
  idherramienta: string,
  nombre: string, 
  descripcion: string,
  haspermiso?: Boolean,
}

@Component({
  selector: 'app-herramienta',
  templateUrl: './herramienta.component.html',
  styleUrls: ['./herramienta.component.css']
})
export class HerramientaComponent implements OnInit {
  lstHerramientas: any;
  displayedColumns: string[] = ['nombre', 'descripcion', 'acciones'];
  dataSource: MatTableDataSource<HerramientaData>;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 
  
  constructor(private herramientaService: HerramientaService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }


  
  async ngOnInit() 
  {
    this.configureHerramientas();
  }

  async configureHerramientas() {
    this.lstHerramientas = await this.herramientaService.getHerramientas();
    this.dataSource = new MatTableDataSource(this.lstHerramientas);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async agregarHerramientaDialog() {
    
    const dialogo = this.dialog.open(DialogHerramientaComponent, {
      height: '420px',
      width: '480px',
      disableClose: true,
      data: { editar: false }
    });

    dialogo.afterClosed().subscribe(async herramienta => {
     
      if (herramienta) {
        try {
          const response = await this.herramientaService.postHerramienta(herramienta).toPromise();
          this.configureHerramientas();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
    
  }
  editarHerramientaDialog(herramienta: any) { 
    const dialogo = this.dialog.open(DialogHerramientaComponent, {
      height: '420px',
      width: '480px',
      disableClose: true,
      data: { herramienta: herramienta, editar: true }
    });

    dialogo.afterClosed().subscribe(async herramienta => {
     
      if (herramienta) {
        try {
          const response = await this.herramientaService.editHerramienta(herramienta).toPromise();
          this.configureHerramientas();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  async deleteHerramienta(herramienta: any) {
    if (herramienta) {
      try {
        await this.herramientaService.deleteHerramienta(herramienta).toPromise();
        this.configureHerramientas();

        this.snackBar.open('Herramienta eliminada con éxito.', '', {
          duration: 5000,
        });
      } catch (error) {
        console.log(error);
        this.snackBar.open('Ocurrió un error al guardar.', '', {
          duration: 5000,
        });
      }
    }
  }
}
