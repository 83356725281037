import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { IEvento } from '../classes/interfaces';
@Injectable({
  providedIn: 'root'
})
export class RegistroEventoService {
  lstEventos:Array<IEvento>;
  eventoSelected: IEvento;
  constructor(private http: HttpClient) { 

  }
  getEventos() {
    return this.http.get<IEvento[]>(Constants.API_URL + '/registroevento/getEventos').toPromise();
  }
  editEvento(evento: IEvento) {
    return this.http.put(`${Constants.API_URL}/registroevento/editaEvento`, evento);
  }
  insertEvento(evento: IEvento) {
    return this.http.post(`${Constants.API_URL}/registroevento/agregaEvento`, evento);
  }
  delEvento(evento: IEvento) {
    return this.http.put(`${Constants.API_URL}/registroevento/eliminaEvento`, evento);
  }
}
