import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from '../../../services/login.service';
import { MenuService, MenuTool } from 'src/services/menu.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css']
})
export class PanelComponent implements OnInit { 

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  menuTool: any = MenuTool;
  titleTool: string = '';

  constructor(private breakpointObserver: BreakpointObserver, 
              private loginService: LoginService,
              private menuService: MenuService) { }

  ngOnInit() {
    this.checkLoginStatus();
    this.openTool(MenuTool.PanelMenuPrincipal);
  }
  checkLoginStatus() {
    if(this.loginService.user && this.loginService.password) {
      const resp: any = this.loginService.login(this.loginService.user.login, this.loginService.password);
      if (resp.length < 1) {
        this.loginService.logOut();
      }
    } else {
      this.loginService.logOut();
    }
  }
  openTool(toolSelected: MenuTool) {
    setTimeout( () => {
      this.configureTitle(toolSelected);
      this.menuService.openTool(toolSelected);
    }, 1000);    
  }
  configureTitle(toolSelected: MenuTool) {
    this.titleTool = ''
    if(toolSelected === MenuTool.PanelMenuPrincipal) {
      this.titleTool += 'Seleccione el Trámite Deseado';
    } else if(toolSelected === MenuTool.PanelNuevaSolicitud) {
      this.titleTool += 'Nueva Solicitud';
    } else if(toolSelected === MenuTool.PanelMisTramites) {
      this.titleTool += 'Mis Trámites';
    } 
  }
  logOut() {
    this.loginService.logOut();
  }

}
