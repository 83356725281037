import { Component, OnInit, ViewChild } from '@angular/core';
import { RegistroEventoService } from 'src/services/registro-evento.service';
import { ConsejoSocialService } from '../../../services/consejo-social.service';
import { IEvento, IConsejoSocial } from '../../../classes/interfaces';
import { MatSnackBar, MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DialogEventoComponent } from './dialog-evento/dialog-evento.component';
import { DialogConsejoSocialComponent } from './dialog-consejo-social/dialog-consejo-social.component';
import { DialogConfirmacionComponent } from '../dialogs/dialog-confirmacion/dialog-confirmacion.component';
import { Constants } from '../../../commons/constants';
import { LogService } from 'src/services/log.service';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-registro-evento',
  templateUrl: './registro-evento.component.html',
  styleUrls: ['./registro-evento.component.css']
})

export class RegistroEventoComponent implements OnInit {
  isLinear = false;
  isServer: Boolean = Constants.IS_SERVER;
  isServerAlive: Boolean = this.loginService.isServerAlive;
  is_admin: string = Constants.IS_ADMIN;
  numAsistentes = 0;
  displayedColumns: string[] = ['nombrecompleto','zona', 'subzona', 'territorio', 
                                'calle', 'numero', 'telefono', 'asistencia', 'acciones'];
  dataSource: MatTableDataSource<IConsejoSocial>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 

  constructor(private registroEventoService: RegistroEventoService,
              private consejoSocialService: ConsejoSocialService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar,
              private logService: LogService,
              private loginService: LoginService) { }

  ngOnInit() {
    this.isServer = Constants.IS_SERVER;
    this.isServerAlive = this.loginService.isServerAlive;
    this.GetEventos();
  }

  async GetEventos() {
    this.registroEventoService.lstEventos = await this.registroEventoService.getEventos();
  }
  selectEvento(evento: IEvento) {
    this.registroEventoService.eventoSelected = evento;
    this.configuraConsejoSocial();
  }
  agregarEventoDialog() {
    let dialogRef = this.dialog.open(DialogEventoComponent, {
      height: '520px',
      width: '480px',
      disableClose: true,
      data: {
        editar: false
      },
    });
    // Recarga los eventos despues de cerrar el registro
    dialogRef.afterClosed().subscribe(async evento => {
      if (evento) {
        let res = await this.registroEventoService.insertEvento(evento).toPromise();
        if (res && res['status'] == 200) {
          this.snackBar.open('Evento registrado satisfactoriamente', 'X', {
            duration: 3000
          });
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `Registré el evento ${evento.nombre}`);     
        } else {
          this.snackBar.open('El Evento no pudo ser registrado', 'X', {duration: 3000});
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR API: El Evento no pudo ser registrado`);     
        }
      }
      this.GetEventos(); 
    });
  }
  editarEventoDialog(event) {
    let dialogRef = this.dialog.open(DialogEventoComponent, {
      height: '520px',
      width: '480px',
      disableClose: true,
      data: {
        editar: true,
        evento: event
      }
    });
    dialogRef.afterClosed().subscribe(async evento => {
      // Aqui editaremos el evento
      if (evento) {
        let resjson = await this.registroEventoService.editEvento(evento).toPromise();
        if (resjson && resjson['status'] == 200) {
           this.snackBar.open('Evento Actualizado con éxito','X', {duration: 3000});
           await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `Actualicé el evento ${evento.nombre}`);     
          } else {
          this.snackBar.open('El evento no pudo ser actualizado', 'X', {duration: 3000})
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR API: El evento no pudo ser actualizado`);     

        }
      }
      this.GetEventos();
    })
  }
  eliminarEvento(evento: IEvento) {
    let dialogRef = this.dialog.open(DialogConfirmacionComponent, {
      width: '300px',
      height: '150px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(async res => {
      // Si se borra
      if (res) {
        try {
          let res = await this.registroEventoService.delEvento(evento).toPromise();
          if(res && res['status'] == 200) {
            this.snackBar.open('Evento borrado con éxito', 'X', {duration: 3000});
            await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `Eliminé el evento ${evento.nombre}`);     
          } else {
            this.snackBar.open('El evento no pudo ser borrado', 'X', {duration: 3000});
            await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR API: El evento no pudo ser borrado`);     
          }
        } catch(error) {
          this.snackBar.open('El evento no pudo ser borrado', 'X', {duration: 3000});
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR APP: El evento no pudo ser borrado`);     
        }
      } else {
        // this.snackBar.open('Canceled', 'X', {duration: 3000});
      }
      this.GetEventos();
    })
  }
  async sincronizarEvento(evento) {
    this.registroEventoService.eventoSelected = evento;
    let dialogRef = this.dialog.open(DialogConfirmacionComponent, {
      width: '300px',
      height: '150px',
      disableClose: true
    });
    dialogRef.beforeClosed().subscribe(async answer => {
      // Do sincronizacion
      if(answer) {
        let dataSincronizar = await this.Sincroniza();
      }
    });
   
    await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `Sincronicé el evento ${evento.nombre}`);     
  }
  async Sincroniza() {
    let estructura = null;
    let insert: Array<IConsejoSocial> = new Array<IConsejoSocial>(0);
    let update: Array<IConsejoSocial> = new Array<IConsejoSocial>(0);
    let lstConsejoSocial: Array<IConsejoSocial> = await this.consejoSocialService.getConsejoSocial(this.registroEventoService.eventoSelected.idevento);
    let lstConsejoSocialServidor: Array<IConsejoSocial> = await this.consejoSocialService.getConsejoSocialServidor(this.registroEventoService.eventoSelected.idevento);
    // Analizamos todos los consejeros sociales nuevos
    lstConsejoSocial.map(cLocal => {
      // Si es consejero social
      if(this.EsConsejeroSocialNuevo(cLocal, lstConsejoSocialServidor)) {
        insert.push(cLocal);
      }
    });
    // Analizamos todos los consejeros sociales actualizar
    lstConsejoSocial.map(cLocal => {
      if (this.EsConsejeroSocialActualizar(cLocal, lstConsejoSocialServidor)) {
        update.push(cLocal);
      }
    });
    if(insert.length > 0 || update.length > 0) {
      try {
        estructura = {
          idevento: this.registroEventoService.eventoSelected.idevento,
          insert: insert,
          update: update
        };
        let res = await this.consejoSocialService.sincronizaConsejoSocial(estructura);
        if(res && res['status'] === 200) {
          this.snackBar.open('Sincronización realizada con éxito', 'X', {duration: 3000});
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `Sincronicé el evento ${this.registroEventoService.eventoSelected.nombre}`);
        } else {
          this.snackBar.open('No se pudo efecturar la sincronización', 'X', { duration: 3000});
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR API: No se pudo sincronizar el evento ${this.registroEventoService.eventoSelected.nombre}`);
        }
      } catch(error) {
        this.snackBar.open('No se pudo sincronizar el evento con el servidor', 'X', {duration: 3000});
        await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR APP: No se pudo sincronizar el evento ${this.registroEventoService.eventoSelected.nombre}`);
      }
    } else {
      this.snackBar.open('No hay información por actualizar en el servidor', 'X', {duration: 3000});
      await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `No habia información por sincronizar ${this.registroEventoService.eventoSelected.nombre}`);
    }
  }

  EsConsejeroSocialNuevo(cLocal: IConsejoSocial, lstConsejoSocial: Array<IConsejoSocial>):Boolean {
    let flag = true;
    lstConsejoSocial.map(cServer => {
       if(cServer.idconsejosocial === cLocal.idconsejosocial) {
        flag = false;
      }
    })
    return flag;
  }
  EsConsejeroSocialActualizar(cLocal: IConsejoSocial, lstConsejoSocial: Array<IConsejoSocial>):Boolean {
    let flag = false;
    lstConsejoSocial.map(cServer => {
       if(cLocal.idconsejosocial === cServer.idconsejosocial  && cLocal.asistencia) {
        flag = true;
      }
    })
    return flag;
  }

  //Region de ConsejoSocial
  async configuraConsejoSocial() {
    this.consejoSocialService.lstConsejoSocial = await this.consejoSocialService.getConsejoSocial(
      this.registroEventoService.eventoSelected.idevento);
      this.dataSource = new MatTableDataSource(this.consejoSocialService.lstConsejoSocial);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    this.numAsistentes = 0;
    const lstAsistieron = this.consejoSocialService.lstConsejoSocial.filter(consejoSocial => {
      return consejoSocial.asistencia && consejoSocial.active;
    });
    this.numAsistentes = lstAsistieron.length;
    /*
    this.consejoSocialService.lstConsejoSocial.map(consejoSocial => {
      if(consejoSocial.asistencia) {
        this.numAsistentes++;
      }
    }); */
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  async agregaConsejoSocial() {
    let dialogRef = this.dialog.open(DialogConsejoSocialComponent, {
      width: '520px',
      height: '780px',
      disableClose: true,
      data: {
        editar: false,
        consejoSocial: null,
      }
    });
    dialogRef.afterClosed().subscribe(async consejoSocial => {
      // Aqui lo guardas
      if (consejoSocial) {
        try {
          let res = await this.consejoSocialService.insertaConsejoSocial(consejoSocial);
          if(res && res['status'] == 200) { 
            this.snackBar.open('Consejero Social insertado con éxito', 'X', { duration: 3000});
            await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `Inserté al consejero social ${consejoSocial.nombre} ${consejoSocial.apellidopaterno} ${consejoSocial.apellidomaterno}`);     
            this.configuraConsejoSocial();
          } else {
            this.snackBar.open('Consejero Social no pudo ser agregado', 'X', {duration: 3000});
            await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR API: Consejero Social no pudo ser agregado`);     
           
          }
        } catch(error) {
          this.snackBar.open('No se pudo agregar el consejero social', 'X', {duration: 3000});
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR APP: Consejero Social no pudo ser agregado`);     
        }
      } else {
        // this.snackBar.open('Canceled', 'X', { duration: 3000});
      }
    })
  }
  async editaConsejoSocial(consejoSocial: IConsejoSocial) {
    let dialogRef = this.dialog.open(DialogConsejoSocialComponent, {
      width: '520px',
      height: '780px',
      disableClose: true,
      data: {
        editar: true,
        consejoSocial: consejoSocial
      }
    });
    dialogRef.afterClosed().subscribe(async consejoSocial => {
      // Aqui lo editas
      if (consejoSocial) {
        try {
          let res = await this.consejoSocialService.editaConsejoSocial(consejoSocial);
          if(res && res['status'] == 200) { 
            this.snackBar.open('Consejero Social editado con éxito', 'X', { duration: 3000});
            await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `Edité al consejero social ${consejoSocial.nombre} ${consejoSocial.apellidopaterno} ${consejoSocial.apellidomaterno}`); 
          } else {
            this.snackBar.open('Consejero Social no pudo ser editado', 'X', {duration: 3000});
            await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR API: No se pudo editar el consejero social`); 
          }
        } catch(error) {
          this.snackBar.open('No se pudo editar el consejero social', 'X', {duration: 3000});
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR APP: No se pudo editar el consejero social`); 
        }
      } else {
        // this.snackBar.open('Canceled', 'X', { duration: 3000});
      }
      this.configuraConsejoSocial();
    })
  }
  async eliminaConsejoSocial(consejoSocial: IConsejoSocial) {
    let dialogRef = this.dialog.open(DialogConfirmacionComponent, {
      width: '300px',
      height: '150px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(async answer => {
      // Aqui lo guardas
      if (answer) {
        try {
          let res = await this.consejoSocialService.eliminaConsejoSocial(consejoSocial);
          if(res && res['status'] == 200) { 
            this.snackBar.open('Consejero Social eliminado con éxito', 'X', { duration: 3000});
            await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `Eliminé al consejero social ${consejoSocial.nombre} ${consejoSocial.apellidopaterno} ${consejoSocial.apellidomaterno}`); 
          } else {
            this.snackBar.open('Consejero Social no pudo ser eliminado', 'X', {duration: 3000});
            await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR API: Consejero Social no pudo ser eliminado`); 
          }
        } catch(error) {
          this.snackBar.open('No se pudo eliminar el consejero social', 'X', {duration: 3000});        
          await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, `ERROR APP: Consejero Social no pudo ser eliminado`); 
        }
        this.configuraConsejoSocial();
      } else {
        // this.snackBar.open('Canceled', 'X', { duration: 3000});
      }
    });
  }
  //END Region ConsejoSocial
}
