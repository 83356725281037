import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { ICirculoCiudadano, ITerritorio, IRedVial, IHttpResponse } from '../classes/interfaces';
@Injectable({
  providedIn: 'root'
})
export class PdfService {
  
  constructor(private http: HttpClient) { }
  
  GetDocumento(nombre: string) {
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/pdf/GetDocumento/${nombre}`).toPromise();
  }
  getAndConvertPdfData(data: any) {
    return this.convertDataURIToBinary(data);
  }
  convertDataURIToBinary(dataURI) {
    const BASE64_MARKER = ';base64,';
    const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
  
}
