import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource, MatTabChangeEvent } from '@angular/material';
import { GrupoService } from 'src/services/grupo.service';
import { SelectionModel } from '@angular/cdk/collections';
import { DialogGrupoComponent } from './dialog-grupo/dialog-grupo.component';
import { VisorService } from 'src/services/visor.service';
import { VisorData } from '../visor/visor.component';
import { ServicioMapaData } from '../servicio-mapa/servicio-mapa.component';
import { ServicioMapaService } from 'src/services/servicio-mapa.service';
import { HerramientaService } from 'src/services/herramienta.service';
import { HerramientaData } from '../herramienta/herramienta.component';

export interface GrupoData {
  idgrupo: string,
  nombre: string, 
  descripcion: string
}

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.css']
})


export class GrupoComponent implements OnInit {
  selectedTabIndex: number = 0;
  lstGrupos: any;
  displayedColumns: string[] = ['nombre', 'descripcion', 'acciones'];
  dataSource: MatTableDataSource<GrupoData>;
  lstUsuariosInGrupo: any = [];
  lstUsuariosNotInGrupo: any = [];
  grupoSelected: GrupoData;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 

  @ViewChild('usuariosNotInGrupo') usuariosNotInGrupo: any;
  @ViewChild('usuariosInGrupo') usuariosInGrupo: any; 

   lstVisores: any;
   visorSelected: VisorData;

   // Servicios asignados a un visor 
   lstServicios: any = [];
   displayedColumnsServicio: string[] = ['select', 'nombre', 'servicio', 'tipo'];
   dataSourceServicio: MatTableDataSource<ServicioMapaData> = new MatTableDataSource([]);
   selection = new SelectionModel<ServicioMapaData>(true, []);

   // Herramientas asignados a un grupo y visor
   lstHerramientas: any = [];
   displayedColumnsHerramienta: string[] = ['select', 'nombre', 'descripcion'];
   dataSourceHerramienta: MatTableDataSource<HerramientaData> = new MatTableDataSource([]);
   selectionHerramienta = new SelectionModel<HerramientaData>(true, []);


  constructor(private grupoService: GrupoService,
              private visorService: VisorService,
              private servicioMapaService: ServicioMapaService,
              private herramientaService: HerramientaService,
              private dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.configureGrupos();
  }
  async configureGrupos() {
    this.lstGrupos = await this.grupoService.getGrupos();
    if(this.lstGrupos.length > 0) {
      this.grupoSelected = this.lstGrupos[0];
    }
    this.dataSource = new MatTableDataSource(this.lstGrupos);
    this.dataSource.paginator = this.paginator;    
    this.dataSource.sort = this.sort;
  }

  async agregarGrupoDialog() {
    
    const dialogo = this.dialog.open(DialogGrupoComponent, {
      height: '420px',
      width: '480px',
      disableClose: true,
      data: { editar: false }
    });

    dialogo.afterClosed().subscribe(async grupo => {
     
      if (grupo) {
        try {
          const response = await this.grupoService.postGrupo(grupo).toPromise();
          this.configureGrupos();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
    
  }

  editarGrupoDialog(grupo: any) { 
    const dialogo = this.dialog.open(DialogGrupoComponent, {
      height: '420px',
      width: '480px',
      disableClose: true,
      data: { grupo: grupo, editar: true }
    });

    dialogo.afterClosed().subscribe(async grupo => {
     
      if (grupo) {
        try {
          const response = await this.grupoService.editGrupo(grupo).toPromise();
          this.configureGrupos();

          this.snackBar.open('Los datos se guardaron exitosamente.', '', {
            duration: 5000,
          });
        } catch (error) {
          console.log(error);
          this.snackBar.open('Ocurrió un error al guardar.', '', {
            duration: 5000,
          });
        }
      }
    });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.selectedTabIndex = tabChangeEvent.index;
}
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  // Tab 2
  openAsignarUsuarios(row: GrupoData) {
    this.grupoSelected = row;
    this.selectedTabIndex = 1;
    this.configureUsuariosGrupo();
  }

  async configureUsuariosGrupo() {
    if (this.grupoSelected) {
      this.lstUsuariosInGrupo = [];
      this.lstUsuariosNotInGrupo = [];
      this.lstUsuariosInGrupo = await this.grupoService.getUsuariosInGrupo(this.grupoSelected.idgrupo);
      this.lstUsuariosNotInGrupo = await this.grupoService.getUsuariosNotInGrupo(this.grupoSelected.idgrupo);    
    } else {
      this.snackBar.open('No hay un grupo seleccionado', '', {
        duration: 3000,
      })
    }
   
  }
  async asignarUsuariosGrupo() {
    
    this.usuariosNotInGrupo.selectedOptions.selected.map(usuario => {
      this.lstUsuariosInGrupo.push(usuario.value);
    });
    for(let i = this.lstUsuariosNotInGrupo.length-1; i >= 0; i--) {
      let remover = false;
      this.usuariosNotInGrupo.selectedOptions.selected.map(u => {
        if(this.lstUsuariosNotInGrupo[i].idusuario === u.value.idusuario) {
          remover = true;
        }
      });
      if(remover) {
        this.lstUsuariosNotInGrupo.splice(i, 1);
      }
    }
    
  }

  async quitarUsuariosGrupo() {
    this.usuariosInGrupo.selectedOptions.selected.map(usuario => {
      this.lstUsuariosNotInGrupo.push(usuario.value);
    });
    for(let i = this.lstUsuariosInGrupo.length-1; i >= 0; i--) {
      let remover = false;
      this.usuariosInGrupo.selectedOptions.selected.map(u => {
        if(this.lstUsuariosInGrupo[i].idusuario === u.value.idusuario) {
          remover = true;
        }
      });
      if(remover) {
        this.lstUsuariosInGrupo.splice(i, 1);
      }
    }
  }

  async actualizarUsuariosGrupo() {
    const resp = await this.grupoService.asignaUsuariosGrupo(this.grupoSelected, this.lstUsuariosInGrupo);
    if(resp['status'] === 200) {
      this.snackBar.open('Usuarios actualizados con éxito', '', {
        duration: 3000
      });  
    } else {
      this.snackBar.open('No se pudieron actualizar los Usuarios', '', {
        duration: 3000
      });  
    }
  }
  // Tab Servicios de Mapas
  openAsignarPermisosServicios(row: GrupoData) {
    this.grupoSelected = row;
    this.selectedTabIndex = 2;
    this.configureVisores();
  }
  async configureVisores() {
    if (this.grupoSelected) {
      this.lstVisores = await this.visorService.getVisores();
      this.lstUsuariosInGrupo = await this.grupoService.getUsuariosInGrupo(this.grupoSelected.idgrupo);
      this.lstUsuariosNotInGrupo = await this.grupoService.getUsuariosNotInGrupo(this.grupoSelected.idgrupo);    
    } else {
      this.snackBar.open('No hay un grupo seleccionado', '', {
        duration: 3000,
      })
    }
   
  }
  selectVisor(visorClicked: VisorData) {
    let that = this;
    this.visorSelected = visorClicked;
    this.configureServicios();
  }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSourceServicio.data.length;
      return numSelected === numRows;
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSourceServicio.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: ServicioMapaData): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idservicio}`;
    }

    async configureServicios() {
      this.lstServicios = await this.servicioMapaService.getServiciosPermisoByVisorAndGrupo(this.visorSelected, this.grupoSelected);
      this.dataSourceServicio = new MatTableDataSource(this.lstServicios);
  
      this.selection = new SelectionModel<ServicioMapaData>(true, this.dataSourceServicio.data.filter(servicio => {
        return servicio.haspermiso;
      }));
    }
    async actualizarPermisoServicioVisorAndGrupo() {
      if(this.visorSelected && this.grupoSelected) {
        await this.servicioMapaService.asignaServiciosVisorAndGrupo(this.grupoSelected, this.visorSelected, this.selection.selected).toPromise();
        this.snackBar.open('Permisos actualizados con éxito', 'X', {
          duration: 3000
        });
      } else {
        this.snackBar.open('Debe tener seleccionado un visor y un grupo', 'X', {
          duration: 3000
        });
      }
    }
  // Tab Herramientas
  openAsignarPermisosHerramientas(row: GrupoData) {
    this.grupoSelected = row;
    this.selectedTabIndex = 3;
    this.configureVisores();
  }
  selectVisorHerramienta(visorClicked: VisorData) {
    let that = this;
    this.visorSelected = visorClicked;
    this.configureHerramientas();
  }
  async configureHerramientas() {
    this.lstHerramientas = await this.herramientaService.getPermisoHerramientasByVisorAndGrupo(this.visorSelected, this.grupoSelected);
    this.dataSourceHerramienta = new MatTableDataSource(this.lstHerramientas);

    this.selectionHerramienta = new SelectionModel<HerramientaData>(true, this.dataSourceHerramienta.data.filter(herramienta => {
      return herramienta.haspermiso;
    }));
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelectedHerramienta() {
      const numSelected = this.selectionHerramienta.selected.length;
      const numRows = this.dataSourceHerramienta.data.length;
      return numSelected === numRows;
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggleHerramienta() {
      this.isAllSelectedHerramienta() ?
          this.selectionHerramienta.clear() :
          this.dataSourceHerramienta.data.forEach(row => this.selectionHerramienta.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabelHerramienta(row?: HerramientaData): string {
      if (!row) {
        return `${this.isAllSelectedHerramienta() ? 'select' : 'deselect'} all`;
      }
      return `${this.selectionHerramienta.isSelected(row) ? 'deselect' : 'select'} row ${row.idherramienta}`;
    }
    async actualizarPermisoHerramientasVisorAndGrupo() {
      if(this.visorSelected && this.grupoSelected) {
        await this.herramientaService.asignaHerramientasVisorAndGrupo(this.grupoSelected, this.visorSelected, this.selectionHerramienta.selected).toPromise();
        this.snackBar.open('Permisos de herramientas actualizados con éxito', 'X', {
          duration: 3000
        });
      } else {
        this.snackBar.open('Debe tener seleccionado un visor y un grupo', 'X', {
          duration: 3000
        });
      }
    }
}
