import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.css']
})
export class MenuPrincipalComponent implements OnInit {
  @Output() openNuevaSolicitud = new EventEmitter<string>();
  @Output() openMisTramites = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }
  
}
