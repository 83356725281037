import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';

@Injectable({
  providedIn: 'root'
})
export class VisorService {

  constructor(private http: HttpClient) { }

  getVisores() {
    return this.http.get<any>(Constants.API_URL + '/seguridad/visor/getVisores').toPromise();
  }
  postVisor(visor: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/visor/postVisor`, visor);
  }
  editVisor(visor: any) {
    return this.http.put(`${Constants.API_URL}/seguridad/visor/editVisor`, visor);
  }
  disableVisor(visor: any) {
    return this.http.post(`${Constants.API_URL}/seguridad/usuario/disableVisor`, visor);
  }
}
