import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { IConsejoSocial, IZona, ISubzona, ITerritorio, ICoordinador, ILocalidad_Territorio } from '../classes/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ConsejoSocialService {
  lstConsejoSocial: Array<IConsejoSocial> = new Array<IConsejoSocial>(0);
  lstZona: Array<IZona> = new Array<IZona>(0);
  lstSubZona: Array<ISubzona> = new Array<ISubzona>(0);
  lstTerritorio: Array<ITerritorio> = new Array<ITerritorio>(0);
  lstCoordinador: Array<ICoordinador> = new Array<ICoordinador>(0);
  lstLocalidad_Territorio: Array<ILocalidad_Territorio> = new Array<ILocalidad_Territorio>(0);
  
  constructor(private http: HttpClient) { 
  
  }
  
  getConsejoSocial(idevento: string) {
    return this.http.get<IConsejoSocial[]>(`${Constants.API_URL}/consejosocial/getConsejoSocial/${idevento}`).toPromise();
  }

  getConsejoSocialServidor(idevento: string) {
    return this.http.get<IConsejoSocial[]>(`${Constants.SERVER_API_URL}/consejosocial/getConsejoSocial/${idevento}`).toPromise();
  }

  getCoordinador() {
    return this.http.get<[]>(`${Constants.API_URL}/catalogo/general/coordinador_social/coordinador`).toPromise();
  }
  
  getLocalidad_Territorio() {
    return this.http.get<[]>(`${Constants.API_URL}/catalogo/general/localidad_territorio/territorio`).toPromise();
  }

  getZona() {
    return this.http.get<[]>(`${Constants.API_URL}/catalogo/general/zona/nombre`).toPromise();
  }
  
  getSubZona(zona: string) {
    return this.http.get<[]>(`${Constants.API_URL}/catalogo/getSubZona/${zona}`).toPromise();
  }
  
  getTerritorio(zona: string, subzona: string) {
    return this.http.get<[]>(`${Constants.API_URL}/catalogo/getTerritorio/${zona}/${subzona}`).toPromise();
  }

  insertaConsejoSocial(consejoSocial: IConsejoSocial) {
    return this.http.post(`${Constants.API_URL}/consejosocial/insertaConsejoSocial`, consejoSocial).toPromise();
  }

  editaConsejoSocial(consejoSocial: IConsejoSocial) {
    return this.http.put(`${Constants.API_URL}/consejosocial/editaConsejoSocial`, consejoSocial).toPromise();
  }

  eliminaConsejoSocial(consejoSocial: IConsejoSocial) {
    return this.http.put(`${Constants.API_URL}/consejosocial/eliminaConsejoSocial`, consejoSocial).toPromise();
  }

  sincronizaConsejoSocial(data: any) {
    return this.http.post(`${Constants.SERVER_API_URL}/consejosocial/sincronizar`, data).toPromise();
  }
}
