import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { LogService } from '../../../services/log.service';

export interface LogData {
  login: string, 
  log: string, 
  ip: string, 
}

@Component({
  selector: 'app-bitacora',
  templateUrl: './bitacora.component.html',
  styleUrls: ['./bitacora.component.css']
})

export class BitacoraComponent implements OnInit {
  
  lstLog: any = [];
  displayedColumns: string[] = ['login', 'log', 'ip', 'fecha'];
  dataSource: MatTableDataSource<LogData>;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 
  
  constructor(private logService: LogService) { }

  ngOnInit() {
    this.configureLog();
  }
  
  async configureLog() {
    this.lstLog = await this.logService.getLog();
    this.dataSource = new MatTableDataSource(this.lstLog);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
