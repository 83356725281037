import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IEvento } from '../../../../classes/interfaces';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-dialog-evento',
  templateUrl: './dialog-evento.component.html',
  styleUrls: ['./dialog-evento.component.css']
})
export class DialogEventoComponent implements OnInit {
  titulo = 'Nuevo Evento';
  evento: IEvento = {
    idevento: '',
    nombre: '',
    fecha: '',
    sede: '',
    observaciones: '',
    fechamodificacion: '',
    idusuario: this.loginService.user.idusuario
  };
  frmCampos: FormGroup;

  constructor(private dialogRef: MatDialogRef<DialogEventoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private loginService: LoginService) { 

  }

  ngOnInit() {
    this.configurarValidadores();
    // Aqui editamos 
    if (this.data.editar) {
      this.titulo = 'Editar Evento';
      this.evento = this.data.evento;
    }

  }
  configurarValidadores() {
    this.frmCampos = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(1)]),
      sede: new FormControl('', [Validators.required, Validators.minLength(1)]),
      observaciones: new FormControl('', []),
      fecha: new FormControl('',[Validators.required, Validators.minLength(1)])
    });
  }

  cancelar() {
    this.dialogRef.close(undefined);
  }

  guardarEvento() {
    this.dialogRef.close(this.evento);
  }
}
