import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from '../../../services/login.service';
import { MenuService, MenuTool } from 'src/services/menu.service';
import { LogService } from 'src/services/log.service';
import { Constants } from '../../../commons/constants';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches)
    );
  menuTool: any = MenuTool;
  titleTool: string = '';
  constructor(private breakpointObserver: BreakpointObserver, 
              private loginService: LoginService,
              private menuService: MenuService,
              private logService: LogService) {}
  
  async ngOnInit() {
    this.checkLoginStatus();
    
  }
  // Cuando se entra en modo local Revisa si hay interconexión con el servidor
  async IsServerAlive() {
    if (!Constants.IS_SERVER) {
      try {
        let res = await this.loginService.IsServerAlive();
        if(res && res['status'] === 200) {
          this.loginService.isServerAlive = true;
        }
      } catch (error) {
        console.log('No hay conexión con el servidor');
      }
    }
  }
  checkLoginStatus() {
    if(this.loginService.user && this.loginService.password) {
      const resp: any = this.loginService.login(this.loginService.user.login, this.loginService.password);
      if (resp.length < 1) {
        this.loginService.logOut();
      }
      this.IsServerAlive();
    } else {
      this.loginService.logOut();
    }
  }
  openTool(toolSelected: MenuTool) {
    this.configureTitle(toolSelected);
    this.menuService.openTool(toolSelected);    
  }

  configureTitle(toolSelected: MenuTool) {
    this.titleTool = ' -> '
    if(toolSelected === MenuTool.Registro) {
      this.titleTool += 'Registro';
    } else if (toolSelected === MenuTool.Reporte) {
      this.titleTool += 'Reportes';
    } else if (toolSelected === MenuTool.Bitacora) {
      this.titleTool += 'Bitacoras';
    } else if (toolSelected === MenuTool.Usuario) {
      this.titleTool += 'Usuarios';
    } else if (toolSelected === MenuTool.Grupo) {
      this.titleTool += 'Grupos';
    }  else if (toolSelected === MenuTool.Visor) {
      this.titleTool += 'Visores';
    }  else if (toolSelected === MenuTool.Herramienta) {
      this.titleTool += 'Herramientas';
    } else if (toolSelected === MenuTool.ServicioMapa) {
      this.titleTool += 'Servicios de Mapas';
    } else if (toolSelected === MenuTool.RegistroEvento) {
      this.titleTool += 'Registro de Consejeros Sociales';
    } else if (toolSelected === MenuTool.CapturaCiudadano) {
      this.titleTool += 'Captura Ciudadanos';
    } else if (toolSelected === MenuTool.CapturaCovid) {
      this.titleTool += 'Captura Covid';
    }
  }

  async logOut() {
    await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, 'Me sali del sistema satisfactoriamente');
    this.loginService.logOut();
  }
  async ngOnDestroy() {
    await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, 'Me sali del sistema satisfactoriamente');    
  }
}
