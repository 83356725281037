import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GrupoService } from '../../../../services/grupo.service';
import { GrupoData } from '../grupo.component';
@Component({
  selector: 'app-dialog-grupo',
  templateUrl: './dialog-grupo.component.html',
  styleUrls: ['./dialog-grupo.component.css']
})
export class DialogGrupoComponent implements OnInit {
  grupo: GrupoData;
  titulo = 'Nuevo Grupo';
  frmCampos: FormGroup;
  lstGrupos: any;

  constructor(private dialogRef: MatDialogRef<DialogGrupoComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              ) { }

  ngOnInit() {
    this.frmCampos = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(4)]),
      descripcion: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });

    if (this.data.editar) {
      this.titulo = 'Editar grupo';
      this.grupo = this.data.grupo;
    } else {
      this.grupo = { idgrupo: '', nombre: '', descripcion: ''};
    }
  }
  
  cancelar() {
    this.dialogRef.close(undefined);
  }
  guardar() {
    this.dialogRef.close(this.grupo);
  }


}
