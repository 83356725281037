import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicioMapaService } from '../../../../services/servicio-mapa.service';
import { ServicioMapaData } from '../servicio-mapa.component';

@Component({
  selector: 'app-dialog-servicio-mapa',
  templateUrl: './dialog-servicio-mapa.component.html',
  styleUrls: ['./dialog-servicio-mapa.component.css']
})
export class DialogServicioMapaComponent implements OnInit {
  servicio: ServicioMapaData;
  titulo = 'Nuevo Servicio de Mapas';
  frmCampos: FormGroup;

  constructor(private dialogRef: MatDialogRef<DialogServicioMapaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngOnInit() {
      this.frmCampos = new FormGroup({
        nombre: new FormControl('', [Validators.required]),
        tipo: new FormControl('', [Validators.required]),
        url: new FormControl('', [Validators.required]),
        servicio: new FormControl('', [Validators.required]),
        opacidad: new FormControl('', [Validators.required]),
      });
  
      if (this.data.editar) {
        this.titulo = 'Editar Servicio de Mapas';
        this.servicio = this.data.servicio;
      } else {
        this.servicio = { idservicio: '',nombre: '', 
                          tipo: 'TileWMS', 
                          url: 'http://localhost:8080/geoserver/arm/wms', servicio: '', opacidad: 1, visible: true, };
      }
    }
    cancelar() {
      this.dialogRef.close(undefined);
    }
    guardar() {
      this.dialogRef.close(this.servicio);
    }
  

}
