import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IConsejoSocial } from '../../../../classes/interfaces';

import { LoginService } from 'src/services/login.service';
import { RegistroEventoService } from 'src/services/registro-evento.service';
import { ConsejoSocialService } from 'src/services/consejo-social.service';
import { ITerritorio, ILocalidad_Territorio } from '../../../../classes/interfaces';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators'
@Component({
  selector: 'app-dialog-consejo-social',
  templateUrl: './dialog-consejo-social.component.html',
  styleUrls: ['./dialog-consejo-social.component.css']
})
export class DialogConsejoSocialComponent implements OnInit {
  titulo = 'Registro Consejo Social';
  consejoSocial: IConsejoSocial = {
    idconsejosocial: '',
    idevento: this.registroEventoService.eventoSelected.idevento,
    zona: '',
    subzona: '',
    territorio: '',
    jefe: '',
    coordinador: '',
    nombre: '',
    apellidopaterno: '',
    apellidomaterno: '',
    nombrecompleto: '',
    calle: '',
    numero: '',
    telefono: '',
    representante: '',
    fechamodificacion: '',
    usuario: this.loginService.user.login,
    active: '',
    asistencia: true,
  }
  frmCampos: FormGroup;
  filteredOptions: Observable<ILocalidad_Territorio[]>;
  myControl = new FormControl();
 
  constructor(private loginService: LoginService,
              private consejoSocialService: ConsejoSocialService,
              private registroEventoService: RegistroEventoService,
              private dialogRef: MatDialogRef<DialogConsejoSocialComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              ) { }

  async ngOnInit() {
    this.configurarValidadores();
    this.configurarAutocomplete();

    // Aqui se edita
    if (this.data.editar) {      
      this.titulo = 'Edita el Consejero Social';
      this.consejoSocial = this.data.consejoSocial;
      
      const tmpJefe: string = this.consejoSocial.jefe;
      const tmpCoordinador: string = this.consejoSocial.coordinador;
      const tmpZona: string = this.consejoSocial.zona;
      const tmpSubzona: string = this.consejoSocial.subzona;
      const tmpTerritorio: string = this.consejoSocial.territorio;
      await this.getLocalidad_Territorio();
      this.consejoSocial.jefe = tmpJefe;
      this.consejoSocial.coordinador = tmpCoordinador;
      this.consejoSocial.zona = tmpZona;
      this.consejoSocial.subzona = tmpSubzona;
      this.consejoSocial.territorio = tmpTerritorio;
    } else {
      // this.getZona();
      this.getLocalidad_Territorio();
    }
    
  }
  async getLocalidad_Territorio() {
    this.consejoSocialService.lstLocalidad_Territorio = await this.consejoSocialService.getLocalidad_Territorio();
    this.consejoSocial.jefe = '';
    this.consejoSocial.coordinador = '';
    this.consejoSocial.zona = '';
    this.consejoSocial.subzona = '';
    this.consejoSocial.territorio = '';
  }


  
  configurarValidadores() {
    this.frmCampos = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(1)]),
      apellidopaterno: new FormControl('', [Validators.required, Validators.minLength(1)]),
      apellidomaterno: new FormControl('', [Validators.required, Validators.minLength(1)]),
    });
  }
  configurarAutocomplete() {
    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.territorio),
        map(name => name ? this._filter(name) : this.consejoSocialService.lstLocalidad_Territorio.slice())
      );
  }
  displayFn(localidad_Territorio?: ILocalidad_Territorio): string | undefined {
    return localidad_Territorio ? localidad_Territorio.territorio : undefined;
  }

  private _filter(name: string): ILocalidad_Territorio[] {
    const filterValue = name.toLowerCase();

    return this.consejoSocialService.lstLocalidad_Territorio.filter(option => option.territorio.toLowerCase().indexOf(filterValue) >= 0);
  }
  autoCompleteChanged(localidad_territorio: ILocalidad_Territorio) {
    this.consejoSocial.zona = localidad_territorio.zona;
    this.consejoSocial.subzona = localidad_territorio.subzona;
    this.consejoSocial.territorio = localidad_territorio.territorio;
    this.consejoSocial.jefe = localidad_territorio.jefe;
    this.consejoSocial.coordinador = localidad_territorio.coordinador;
  }
  cancelar() {
    this.dialogRef.close(null);
  }

  guardar() {
    this.consejoSocial.active = 'true';
    this.dialogRef.close(this.consejoSocial);
  }
}
