import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { LoginService } from 'src/services/login.service';
import { UserData } from '../usuario/usuario.component';
import { LogService } from 'src/services/log.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Constants } from '../../../commons/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  status: number =  0;
  username: string;
  password: string;
  
  // nuevo usuario
  
  nuevo_email: string;
  nuevo_username: string;
  nuevo_password: string;
  nuevo_confirmar_password: string;

  frmCampos: FormGroup;

  constructor(private router:Router, 
              private ls: LoginService,
              private logService: LogService,
              private snackBar: MatSnackBar) { }
  
  async ngOnInit() {
    this.configurarValidadores();
    this.ls.user = null;

}
  configurarValidadores() {
    this.frmCampos = new FormGroup({
      rfc: new FormControl('', [Validators.required, Validators.minLength(12), Validators.maxLength(13)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(5)]),
      confirmarpassword: new FormControl('', [Validators.required, Validators.minLength(5)]),
    });  
}
  async login() {
    try {
      const resp: any = await this.ls.login(this.username, this.password);
      if(this.ls.user.activo && this.ls.user.idusuario !== '') {
        await this.logService.postLog(this.ls.user.idusuario, this.ls.user.login, 'Me autentique satisfactoriamente'); 
        this.router.navigate(["panel"]);
       } else {
        await this.logService.postLog('', this.username, 'Usuario y/o Contraseña ingresada de manera incorrecta'); 
        this.snackBar.open('Usuario y/o Contraseña ingresada de manera incorrecta.', '', {
          duration: 5000,
        });
       }  
    } catch (error) {
      await this.logService.postLog('', this.username, 'Usuario y/o Contraseña ingresada de manera incorrecta'); 
      this.snackBar.open('Usuario y/o contraseña erronea. .', '', {
        duration: 5000,
      });
    }
  }
  changeState(estado: number) {
    this.status = estado;
  }


  async registrarse() {
    try {
      if (this.rfcValido(this.nuevo_username) !== false) {
        if (this.nuevo_password === this.nuevo_confirmar_password) {
          const resp:any = await this.ls.postUsuario(this.nuevo_email, this.nuevo_username, this.nuevo_password);
          if (resp.status == 200) {
            this.snackBar.open('Usuario autenticado con éxito. Favor de autenticarse', 'X', {duration:3000});
            this.nuevo_email = '';
            this.nuevo_username = '';
            this.nuevo_password = '';
            this.nuevo_confirmar_password = '';
            this.status = 0;
          }
        } else {
          this.snackBar.open('Las contraseñas deben de ser iguales', 'X', {duration: 3000});
        }
      } else {
        this.snackBar.open('Debe de registrarse con un RFC válido', 'X', {duration: 3000});
      } 
    } catch(error) {
      this.snackBar.open('Hubo un error al momento de registrarse. Favor de volverlo a intentar', 'X', {duration: 3000});
    }
  } 
  //Función para validar un RFC
// Devuelve el RFC sin espacios ni guiones si es correcto
// Devuelve false si es inválido
// (debe estar en mayúsculas, guiones y espacios intermedios opcionales)
rfcValido(rfc, aceptarGenerico = true) {
  const re       = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  var   validado = rfc.match(re);

  if (!validado)  //Coincide con el formato general del regex?
      return false;

  //Separar el dígito verificador del resto del RFC
  const digitoVerificador = validado.pop(),
        rfcSinDigito      = validado.slice(1).join(''),
        len               = rfcSinDigito.length,

  //Obtener el digito esperado
        diccionario       = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
        indice            = len + 1;
  var   suma,
        digitoEsperado;

  if (len == 12) suma = 0
  else suma = 481; //Ajuste para persona moral

  for(var i=0; i<len; i++)
      suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  digitoEsperado = 11 - suma % 11;
  if (digitoEsperado == 11) digitoEsperado = 0;
  else if (digitoEsperado == 10) digitoEsperado = "A";

  //El dígito verificador coincide con el esperado?
  // o es un RFC Genérico (ventas a público general)?
  if ((digitoVerificador != digitoEsperado)
   && (!aceptarGenerico || rfcSinDigito + digitoVerificador != "XAXX010101000"))
      return false;
  else if (!aceptarGenerico && rfcSinDigito + digitoVerificador == "XEXX010101000")
      return false;
  return rfcSinDigito + digitoVerificador;
 }
}
