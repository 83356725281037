import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material/material.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { DashboardComponent } from './dashboard.component';
import { UsuarioComponent } from '../usuario/usuario.component';
import { DialogUsuarioComponent } from '../usuario/dialog-usuario/dialog-usuario.component';

import { BitacoraComponent } from '..//bitacora/bitacora.component';
import { ReporteComponent } from '..//reporte/reporte.component';
import { GrupoComponent } from '../grupo/grupo.component';
import { DialogGrupoComponent } from '../grupo/dialog-grupo/dialog-grupo.component';
import { VisorComponent } from '../visor/visor.component';
import { DialogVisorComponent } from '../visor/dialog-visor/dialog-visor.component';
import { HerramientaComponent } from '../herramienta/herramienta.component';
import { DialogHerramientaComponent } from '../herramienta/dialog-herramienta/dialog-herramienta.component';
import { ServicioMapaComponent } from '../servicio-mapa/servicio-mapa.component';
import { DialogServicioMapaComponent } from '../servicio-mapa/dialog-servicio-mapa/dialog-servicio-mapa.component';
import { RegistroEventoComponent } from '../registro-evento/registro-evento.component';
import { DialogEventoComponent } from '../registro-evento/dialog-evento/dialog-evento.component';
import { DialogConsejoSocialComponent } from '../registro-evento/dialog-consejo-social/dialog-consejo-social.component';
import { DialogConfirmacionComponent } from '../dialogs/dialog-confirmacion/dialog-confirmacion.component';
import { CapturaCiudadanoComponent } from '../captura-ciudadano/captura-ciudadano.component';
import { DialogCapturaComponent } from '../captura-ciudadano/dialog-captura/dialog-captura.component';
import { MapaComponent } from '../../components/mapa/mapa.component';
import { CapturaCovidComponent } from '../../components/captura-covid/captura-covid.component';
import { DialogCovidComponent } from '../../components/captura-covid/dialog-covid/dialog-covid.component';
import { DialogResponsableComponent } from '../../components/captura-covid/dialog-responsable/dialog-responsable.component';

//Servicios
import { LoginService } from '../../../services/login.service';
import { LogService } from '../../../services/log.service';
import { MenuService } from '../../../services/menu.service';
import { UsuarioService } from '../../../services/usuario.service';
import { RegistroService } from '../../../services/registro.service';
import { ReporteService } from '../../../services/reporte.service';
import { GrupoService } from '../../../services/grupo.service';
import { VisorService } from '../../../services/visor.service';
import { HerramientaService } from '../../../services/herramienta.service';
import { ServicioMapaService } from '../../../services/servicio-mapa.service';
import { RegistroEventoService } from '../../../services/registro-evento.service';
import { ConsejoSocialService } from '../../../services/consejo-social.service';
import { CirculoCiudadanoService } from '../../../services/circulo-ciudadano.service';
import { MapaService } from '../../../services/mapa.service';
import { CapturaCovidService } from '../../../services/captura-covid.service';
import { PdfService } from '../../../services/pdf.service';

// Pipes
import { SafeUrlPipe } from '../../../pipes/safeurl.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PdfViewerModule
  ],

  declarations: [ 
    DashboardComponent,
    UsuarioComponent,
    DialogUsuarioComponent,
    BitacoraComponent,
    ReporteComponent,
    VisorComponent,
    DialogVisorComponent,
    GrupoComponent,
    DialogGrupoComponent,
    HerramientaComponent,
    DialogHerramientaComponent,
    ServicioMapaComponent,
    DialogServicioMapaComponent,
    RegistroEventoComponent,
    DialogEventoComponent,
    DialogConsejoSocialComponent,
    DialogConfirmacionComponent,
    CapturaCiudadanoComponent,
    CapturaCovidComponent,
    DialogResponsableComponent,
    SafeUrlPipe,
  ],
  entryComponents: [DialogUsuarioComponent, DialogGrupoComponent, DialogVisorComponent, DialogHerramientaComponent, 
                    DialogServicioMapaComponent, DialogEventoComponent, DialogConsejoSocialComponent, 
                    DialogConfirmacionComponent, DialogResponsableComponent],
  providers: [ LoginService, MenuService, UsuarioService, LogService, RegistroService, ReporteService, 
              GrupoService, VisorService, HerramientaService, ServicioMapaService, RegistroEventoService,
              ConsejoSocialService, CirculoCiudadanoService, MapaService, CapturaCovidService, DatePipe, PdfService],
})
export class DashboardModule { }
