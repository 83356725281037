import { Component, OnInit, ViewChild } from '@angular/core';
import { ReporteService } from 'src/services/reporte.service';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Constants } from '../../../commons/constants';
import { LoginService } from 'src/services/login.service';
import { LogService } from 'src/services/log.service';

@Component({
  selector: 'app-reporte',
  templateUrl: './reporte.component.html',
  styleUrls: ['./reporte.component.css']
})
export class ReporteComponent implements OnInit {
  
  displayedColumns: string[] = ['folio', 'nombre', 'clase', 'anonacimiento', 'lugarnacimiento',
                                'nombrePadre', 'nombreMadre', 'estadoCivil', 'ocupacion', 'gradoestudio',
                                'domicilio', 'telefono', 'celular', 'correoelectronico'];
  dataSource: MatTableDataSource<any>;
  lstCiudadanos: any;
  clase = '2000';
  ano = '2019';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort; 

  constructor(private reporteService: ReporteService,
              private loginService: LoginService,
              private logService: LogService,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.configureCiudadanos();
  }

  async configureCiudadanos() {
    this.lstCiudadanos = await this.reporteService.getAllCiudadanos();
    this.dataSource = new MatTableDataSource(this.lstCiudadanos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  async BuscarClase() {
    this.lstCiudadanos = await this.reporteService.getCiudadanosByClase(this.clase); 
    this.dataSource = new MatTableDataSource(this.lstCiudadanos);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ImprimeFormatoListaRegistro() {
    const resp = await this.reporteService.generaListaDeRegistro(this.clase);
    await this.logService.postLog(this.loginService.user.idusuario,this.loginService.user.login, 'Imprimí el Reporte Lista de Registro de la clase: ' + this.clase);
    this.AbrirReporte(resp);
  }

  async ImprimeFormatoBalance() {
    const resp = await this.reporteService.generaBalance(this.clase);    
    await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, 'Imprimí el Reporte Balance de la clase: ' + this.clase);
    this.AbrirReporte(resp);

  }

  async ImprimeFormatoLibroRegistro() {
    const resp = await this.reporteService.generaLibroRegistro(this.clase);
    await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, 'Imprimí el Reporte Libro de Registro de la clase: ' + this.clase);
    this.AbrirReporte(resp);
  }
  
  async ImprimeFormatoSorteo() {
    const resp = await this.reporteService.generaSorteo(this.clase);
    await this.logService.postLog(this.loginService.user.idusuario, this.loginService.user.login, 'Imprimí el Reporte Sorteo de la clase: ' + this.clase);
    this.AbrirReporte(resp);
  }

  async ImprimeFormatoListaSemanal() {
    const resp = await this.reporteService.generaListaSemanal(this.ano);
    await this.logService.postLog(this.loginService.user.idusuario,this.loginService.user.login, 'Imprimí el Reporte Lista Semanal del año: ' + this.ano);
    this.AbrirReporte(resp);
  }

  AbrirReporte(resp: any) {
    if(resp.url) {
      window.open(Constants.FILES_URL + '/' + resp.url, '_blank');
    }
    this.snackBar.open('Reporte generado con éxito', '', {
      duration: 3000,
    });
  }
}
