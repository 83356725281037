import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  
  log: {
    idusuario: string,
    login: string,
    observacion: string
  };

  constructor(private http: HttpClient) { }

  getLog() {
    return this.http.get<any>(Constants.API_URL + '/seguridad/log/getLog').toPromise();
  }

  postLog(idusuario: string, login: string,  observacion: string) {
    this.log = { idusuario: idusuario, observacion: observacion, login: login };
    return this.http.post<any>(Constants.API_URL + '/seguridad/log/create', this.log).toPromise();
  }

}
