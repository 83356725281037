import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../commons/constants';

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(private http: HttpClient) { }
  
  getAllCiudadanos() {  
    return this.http.get<any[]>(Constants.API_URL + '/reporte/getAllCiudadanos').toPromise();
  }

  getCiudadanosByClase(clase: string) {  
    return this.http.get<any[]>(Constants.API_URL + '/reporte/getCiudadanosByClase?clase=' + clase).toPromise();
  }

  generaListaDeRegistro(clase: string) {  
    return this.http.get<any[]>(Constants.API_URL + '/reporte/generaListaDeRegistro?clase=' + clase).toPromise();
  }

  generaBalance(clase: string) {  
    return this.http.get<any[]>(Constants.API_URL + '/reporte/generaBalance?clase=' + clase).toPromise();
  }

  generaLibroRegistro(clase: string) {  
    return this.http.get<any[]>(Constants.API_URL + '/reporte/generaLibroRegistro?clase=' + clase).toPromise();
  }

  generaSorteo(clase: string) {  
    return this.http.get<any[]>(Constants.API_URL + '/reporte/generaSorteo?clase=' + clase).toPromise();
  }

  generaListaSemanal(ano: string) {  
    return this.http.get<any[]>(Constants.API_URL + '/reporte/generaListaSemanal?ano=' + ano).toPromise();
  }
}
