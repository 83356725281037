import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class MenuService {
  menu: MenuTool = MenuTool.Nada;
  constructor() { 
  }

  openTool(menuTool: MenuTool) {
    this.menu = menuTool;
  }
}

export enum MenuTool {
    Registro,
    RegistroEvento,
    Reporte,
    Bitacora,
    Usuario, 
    Grupo,
    Visor,
    Servicio,
    Herramienta,
    PermisoHerramienta,
    PermisoServicio,
    Nada,
    ServicioMapa,
    CapturaCiudadano,
    CapturaCovid,
    // Panel
    PanelMenuPrincipal,
    PanelNuevaSolicitud,
    PanelMisTramites,

}
