import { environment } from '../environments/environment';

export namespace Constants {
    export const API_URL = environment.API_URL;
    export const SERVER_API_URL = environment.SERVER_API_URL;
    export const FILES_URL = environment.FILES_URL;
    export const PHOTO_URL = environment.PHOTO_URL;
    export const IS_SERVER = environment.IS_SERVER;
    export const IS_ADMIN = environment.IS_ADMIN;
    export const ROOT_URL = environment.ROOT_URL;
    
}