import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Constants } from '../commons/constants';
import { ICirculoCiudadano, ITerritorio, IRedVial, IHttpResponse } from '../classes/interfaces';
@Injectable({
  providedIn: 'root'
})
export class CirculoCiudadanoService {
  
  lstCirculoCiudadano: Array<ICirculoCiudadano> = new Array<ICirculoCiudadano>(0);
  lstVocal: Array<ICirculoCiudadano> = new Array<ICirculoCiudadano>(0);
  lstColonia: Array<ITerritorio> = new Array<ITerritorio>(0);
  lstRedVial: Array<IRedVial> = new Array<IRedVial>(0);
  lstRedVialCruce: Array<IRedVial> = new Array<IRedVial>(0);

  constructor(private http: HttpClient) { }
  
  GetCirculoCiudadano() {
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/circuloCiudadano/GetCirculoCiudadano`).toPromise();
  }
  
  GetColonia() {
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/catalogo/GetColonia`).toPromise();
  }

  

  GetRedVialByColonia(colonia: string){
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/catalogo/GetRedVialByColonia/${colonia}`).toPromise();
  }

  GetRedVialCruce(nombreRedVial: string, geom: string) {
    const body = {
      nombre: nombreRedVial,
      geometry: geom
    };
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/catalogo/GetRedVialCruce`, body).toPromise();
  }
  PostCirculoCiudadano(circuloCiudadano: ICirculoCiudadano) {
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/circuloCiudadano/PostCirculoCiudadano`, circuloCiudadano).toPromise();
  }

  PutCirculoCiudadano(circuloCiudadano: ICirculoCiudadano) {
    return this.http.put<IHttpResponse>(`${Constants.API_URL}/circuloCiudadano/PutCirculoCiudadano`, circuloCiudadano).toPromise();
  }

  DelCirculoCiudadano(circuloCiudadano: ICirculoCiudadano) {
    return this.http.put<IHttpResponse>(`${Constants.API_URL}/circuloCiudadano/DelCirculoCiudadano`, circuloCiudadano).toPromise();
  }
  // Vocales
  GetVocal(idcirculociudadano: string) {
    return this.http.get<IHttpResponse>(`${Constants.API_URL}/circuloCiudadano/GetVocal/${idcirculociudadano}`).toPromise();
  }
  PostVocal(vocal: ICirculoCiudadano) {
    return this.http.post<IHttpResponse>(`${Constants.API_URL}/circuloCiudadano/PostVocal`, vocal).toPromise();
  }

  PutVocal(vocal: ICirculoCiudadano) {
    return this.http.put<IHttpResponse>(`${Constants.API_URL}/circuloCiudadano/PutVocal`, vocal).toPromise();
  }

  DelVocal(vocal: ICirculoCiudadano) {
    return this.http.put<IHttpResponse>(`${Constants.API_URL}/circuloCiudadano/DelVocal`, vocal).toPromise();
  }
}
